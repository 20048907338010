import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";
// import SectionNotStartedImg from '../Assets/Form-Section-Not-Started.png';
// import SectionInProgressImg from '../Assets/Form-Section-In-Progress.png';
// import SectionCompletedImg from '../Assets/Form-Section-Completed.png';
// import FileUploadImg from '../Assets/file-upload.png';
// import AlertImg from '../Assets/Alert.png';
// import EditImg from '../Assets/edit.png';
import ReactPlayer from 'react-player';
import { uploadResumeToBlob, submitJobApplicationForm } from '../Services/functionAppService';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  import { v4 as uuidv4 } from 'uuid';

function EnggJobApplicationPage(){
    const history = useHistory();

    const [SectionNotStartedImg, setSectionNotStartedImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/Form-Section-Not-Started.png");
    const [SectionInProgressImg, setSectionInProgressImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/Form-Section-In-Progress.png");
    const [SectionCompletedImg, setSectionCompletedImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/Form-Section-Completed.png");
    const [FileUploadImg, setFileUploadImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/file-upload.png");
    const [AlertImg, setAlertImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/Alert.png");
    const [EditImg, setEditImg] = useState("https://oahwebsitestorage.blob.core.windows.net/files/edit.png");

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
      }

    let { role } = useParams();

    console.log(replaceAll(role, '-', ' '));

    const [jobRole, setJobRole] = useState(replaceAll(role, '-', ' '));
    
    
    const JARef = useRef();
    
    const [checked, setChecked] = React.useState(true);
    const [selectedEducationLevel, setEducationLevel] = useState(null);
    const [selectedJobSource, setJobSource] = useState(null);
    const [selectedWorkHours, setWorkHours] = useState(null);
    const [selectedGender, setGender] = useState(null);
    const [selectedHispLat, setHispLat] = useState(null);
    const [selectedVeteranStatus, setVeteranStatus] = useState(null);
    const [selectedDisabilityStatus, setDisabilityStatus] = useState(null);
    

    const [formActive, setFormActive] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    function getSessionStorageOrDefault(key, defaultValue) {
        const stored = sessionStorage.getItem(key);
        if (!stored) {
          return defaultValue;
        }
        return JSON.parse(stored);
    };

    const [jobApplicationFormPage, setPage] = useState(getSessionStorageOrDefault('activePage', "MyInformation"));

    const myInfoFormValidityInitialValues = {
        FirstName: false,
        LastName: false,
        Email: false,
        Phone: false,
        Address: false,
        City: false,
        State: false,
        Zip: false,
        ResumeLink: false,
        UniqueFitPitch: false,
        CandidateEducationLevel: false,
        JobSource: false
    }

    const engineeringExperienceFormValidityInitialValues = {
        SoftwareYears: false,
        ProgrammingLanguages: false,
        TeamLeadership: false,
        ChallengingProblem: false,
        SystemArchitecture: false,
        WorkAuthorization: false,
    }

    const WAFormValidityInitialValues = {
        ExpectedWorkHours: false
    }

    const PRFormValidityInitialValues = {
        Reference1Name: false,
        Reference1Relationship: false,
        Reference1Email: false,
        Reference1Phone: false,
        Reference2Name: false,
        Reference2Relationship: false,
        Reference2Email: false,
        Reference2Phone: false,
        Reference3Name: false,
        Reference3Relationship: false,
        Reference3Email: false,
        Reference3Phone: false,
    };

    const myInfoFormTouchedStateInitialValues = {
        FirstName: false,
        LastName: false,
        Email: false,
        Phone: false,
        Address: false,
        City: false,
        State: false,
        Zip: false,
        ResumeLink: false,
        UniqueFitPitch: false,
        CandidateEducationLevel: false,
        JobSource: false
    }

    const engineeringExperienceFormTouchedStateInitialValues = {
        SoftwareYears: false,
        ProgrammingLanguages: false,
        TeamLeadership: false,
        ChallengingProblem: false,
        SystemArchitecture: false,
        WorkAuthorization: false
    }

    const WAFormTouchedStateInitialValues = {
        ExpectedWorkHours: false
    }

    const PRFormTouchedStateInitialValues = {
        Reference1Name: false,
        Reference1Relationship: false,
        Reference1Email: false,
        Reference1Phone: false,
        Reference2Name: false,
        Reference2Relationship: false,
        Reference2Email: false,
        Reference2Phone: false,
        Reference3Name: false,
        Reference3Relationship: false,
        Reference3Email: false,
        Reference3Phone: false
    };

    const GlobalInformationFormInitialValues = {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Address: "",
        City: "",
        State: "",
        Zip: "",
        ResumeLink: "",
        FileName: "No File Selected",
        UniqueFitPitch: "",
        CandidateEducationLevel: null,
        CandidateInSchool: false,
        JobSource: null,
        JobSourceContactName: "",
        FormValid: false
    };

    const [globalInformationFormData, setGlobalInformationData] = useState(getSessionStorageOrDefault('myInfo', GlobalInformationFormInitialValues));
    
    const EngineeringExperienceFormInitialValues = {
        FormValid: false,
        SoftwareYears: "",
        ProgrammingLanguages: "",
        TeamLeadership: "",
        ChallengingProblem: "",
        SystemArchitecture: "",
        WorkAuthorization: ""
    };

    const [globalEEFormData, setGlobalEEFormData] = useState(getSessionStorageOrDefault('enggExperience', EngineeringExperienceFormInitialValues));

    const WorkAvailabilityFormInitialValues = {
        FormValid: false,
        ExpectedWorkHours: "",
        MonDayShift: false,
        MonSwingShift: false,
        MonNightShift: false,
        TueDayShift: false,
        TueSwingShift: false,
        TueNightShift: false,
        WedDayShift: false,
        WedSwingShift: false,
        WedNightShift: false,
        ThuDayShift: false,
        ThuSwingShift: false,
        ThuNightShift: false,
        FriDayShift: false,
        FriSwingShift: false,
        FriNightShift: false,
        SatDayShift: false,
        SatSwingShift: false,
        SatNightShift: false,
        SunDayShift: false,
        SunSwingShift: false,
        SunNightShift: false
    };

    const [globalWAFormData, setGlobalWAFormData] = useState(getSessionStorageOrDefault('workAvailability', WorkAvailabilityFormInitialValues));

    const VoluntaryDisclosuresFormInitialValues = {
        FormValid: false,
        Gender: null,
        HispanicLatino: null,
        VeteranStatus: null,
        DisabilityStatus: null
    };

    const [globalVDFormData, setGlobalVDFormData] = useState(getSessionStorageOrDefault('vDisclosures', VoluntaryDisclosuresFormInitialValues));

    const ReferencesFormInitialValues = {
        FormValid: false,
        Reference1Name: "",
        Reference1Relationship: "",
        Reference1Position: "",
        Reference1Company: "",
        Reference1Email: "",
        Reference1PhoneNumber: "",
        Reference2Name: "",
        Reference2Relationship: "",
        Reference2Position: "",
        Reference2Company: "",
        Reference2Email: "",
        Reference2PhoneNumber: "",
        Reference3Name: "",
        Reference3Relationship: "",
        Reference3Position: "",
        Reference3Company: "",
        Reference3Email: "",
        Reference3PhoneNumber: ""
    };

    const [globalPRFormData, setGlobalPRFormData] = useState(getSessionStorageOrDefault('pReferences', ReferencesFormInitialValues));

    const ReferenceAcknowledgementInitialValues = {
        AllValid: false,
        PhoneNumber: false,
        Relations: false,
        Other: false
    };

    const [globalPRAckData, setGlobalPRAckData] = useState(getSessionStorageOrDefault('pRefAcknowledgements', ReferenceAcknowledgementInitialValues));

    useEffect(() => {
        sessionStorage.setItem('pRefAcknowledgements', JSON.stringify(globalPRAckData));
    }, [globalPRAckData])

    useEffect(() => {
        sessionStorage.setItem('enggExperience', JSON.stringify(globalEEFormData));
    }, [globalEEFormData])

    useEffect(() => {
        sessionStorage.setItem('myInfo', JSON.stringify(globalInformationFormData));
    }, [globalInformationFormData])

    useEffect(() => {
        sessionStorage.setItem('workAvailability', JSON.stringify(globalWAFormData));
    }, [globalWAFormData])

    useEffect(() => {
        sessionStorage.setItem('vDisclosures', JSON.stringify(globalVDFormData));
    }, [globalVDFormData])

    useEffect(() => {
        sessionStorage.setItem('activePage', JSON.stringify(jobApplicationFormPage));
    }, [jobApplicationFormPage])

    useEffect(() => {
        sessionStorage.setItem('pReferences', JSON.stringify(globalPRFormData));
    }, [globalPRFormData])

    function isEmail(value){
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }

    function isNumeric(value) {
        return /^\d{10}$/.test(value);
    }

    const goToExperience = (data) => {
        setPage("Experience");

        data.FormValid = true;

        console.log(data);

        setGlobalInformationData(data);

        scrollToTopOfApplication();
    }

    const goToWorkAvailability = (data) => {
        setPage("WorkAvailability");

        data.FormValid = true;

        console.log(data);

        setGlobalInformationData(data);

        scrollToTopOfApplication();
    }

    const goBackToMyInfo = (data) => {
        setPage("MyInformation");

        console.log(data);

        setGlobalWAFormData(data);

        scrollToTopOfApplication();
    }

    const goToVoluntaryDisclosures = (data) => {
        setPage("VoluntaryDisclosures");

        data.FormValid = true;

        console.log(data);

        //setGlobalWAFormData(data);
        setGlobalEEFormData(data);

        scrollToTopOfApplication();
    }

    const goBackToWorkAvailability = (data) => {
        setPage("WorkAvailability");

        console.log(data);

        setGlobalVDFormData(data);

        scrollToTopOfApplication();
    }

    const goBackToExperience = (data) => {
        setPage("Experience");

        console.log(data);

        setGlobalVDFormData(data);

        scrollToTopOfApplication();
    }

    const goToProfessionalReferences = (data) => {
        setPage("ProfessionalReferences");

        data.FormValid = true;

        console.log(data);

        setGlobalVDFormData(data);

        scrollToTopOfApplication();
    }

    const goBackToVoluntaryDisclosures = (data, ackData) => {
        setPage("VoluntaryDisclosures");

        console.log(data);

        console.log(ackData);

        setGlobalPRFormData(data);

        setGlobalPRAckData(ackData);

        scrollToTopOfApplication();
    }

    const goToReview = (data, ackData) => {
        setPage("Review");

        data.FormValid = true;

        console.log(data);

        console.log(ackData);

        setGlobalPRFormData(data);

        setGlobalPRAckData(ackData);

        var formData = {
            candidateInformation: globalInformationFormData,
            workAvailability: globalWAFormData,
            voluntaryDisclosures: globalVDFormData,
            references: data
        };

        console.log("Job Application Data");

        console.log(formData);

        scrollToTopOfApplication();
    }

    const goBackToProfessionalReferences = () => {
        setPage("ProfessionalReferences");

        scrollToTopOfApplication();
    }

    const scrollToTopOfApplication = () => {
        const node = JARef.current;
        node.scrollTop = 0;
    }

    const goToCareersView = () => {
        history.push("/careers");
    }

    const goToInternshipsView = () => {
        history.push("/internship");
    }

    const submitCompletedJobApplication = async () => {
        const defaultWorkAvailability = {
            FormValid: true,
            ExpectedWorkHours: "40",
            MonDayShift: true,
            MonSwingShift: false,
            MonNightShift: false,
            TueDayShift: true,
            TueSwingShift: false,
            TueNightShift: false,
            WedDayShift: true,
            WedSwingShift: false,
            WedNightShift: false,
            ThuDayShift: true,
            ThuSwingShift: false,
            ThuNightShift: false,
            FriDayShift: true,
            FriSwingShift: false,
            FriNightShift: false,
            SatDayShift: false,
            SatSwingShift: false,
            SatNightShift: false,
            SunDayShift: false,
            SunSwingShift: false,
            SunNightShift: false
        };

        var candidateInfo = globalInformationFormData;

        candidateInfo.UniqueFitPitch = globalInformationFormData.UniqueFitPitch + "\n\n" + "Software Engineering Experience: " + globalEEFormData.SoftwareYears + " years" + "\n" + "Programming Languages: " + globalEEFormData.ProgrammingLanguages + "\n" + "Team Leadership: " + globalEEFormData.TeamLeadership + "\n" + "Challenging Problem: " + globalEEFormData.ChallengingProblem + "\n" + "System Architecture: " + globalEEFormData.SystemArchitecture + "\n" + "Work Authorization: " + globalEEFormData.WorkAuthorization;

        var formData = {
            candidateInformation: candidateInfo,
            workAvailability: defaultWorkAvailability,
            voluntaryDisclosures: globalVDFormData,
            references: globalPRFormData,
            Role: jobRole
        };

        setFormActive(false);
        setShowLoading(true);

        window.grecaptcha.enterprise.ready(async function() {
            window.grecaptcha.enterprise.execute('6LeNno8fAAAAAJ2o6IRoqevzDxplPZRrqcMpWxbt', {action: 'submit'}).then(async function(token) {
                console.log("ReCAPTCHA token");
                console.log(token);

                const functionAppResponse = await submitJobApplicationForm(formData);

        console.log(functionAppResponse);

            if(functionAppResponse.status === 200){
                setShowLoading(false);
                
                setShowSuccess(true);

                setGlobalInformationData(GlobalInformationFormInitialValues);
                setGlobalWAFormData(WorkAvailabilityFormInitialValues);
                setGlobalVDFormData(VoluntaryDisclosuresFormInitialValues);
                setGlobalPRFormData(ReferencesFormInitialValues);

                sessionStorage.setItem('myInfoValidation', JSON.stringify(myInfoFormValidityInitialValues));
                sessionStorage.setItem('workAvailabilityValidation', JSON.stringify(WAFormValidityInitialValues));
                sessionStorage.setItem('pReferencesValidation', JSON.stringify(PRFormValidityInitialValues));

                sessionStorage.setItem('myInfoFormState', JSON.stringify(myInfoFormTouchedStateInitialValues));
                sessionStorage.setItem('workAvailabilityFormState', JSON.stringify(WAFormTouchedStateInitialValues));
                sessionStorage.setItem('pReferencesFormState', JSON.stringify(PRFormTouchedStateInitialValues));

                sessionStorage.setItem('activePage', JSON.stringify("MyInformation"));

                setTimeout(() => {
                    if(jobRole == "Scholars In Disability"){
                        history.push("/internship");
                    } else {
                        history.push("/careers");
                    }
                    
                 }, 5000);
            } else {
                setShowLoading(false);
                setShowFailure(true);
            }

            });
        });

        

        
    }

    const educationLevels = [
        {
          value: "High School or Equivalent",
          label: "High School or Equivalent"
        },
        {
          value: "Technical or Occupational Certificate",
          label: "Technical or Occupational Certificate"
        },
        {
          value: "Associate Degree",
          label: "Associate Degree"
        },
        {
          value: "Some College Coursework Completed",
          label: "Some College Coursework Completed"
        },
        {
            value: "Bachelor’s Degree",
            label: "Bachelor’s Degree"
        },
        {
            value: "Master’s Degree",
            label: "Master’s Degree"
        },
        {
            value: "Doctorate",
            label: "Doctorate"
        },
        {
            value: "Professional",
            label: "Professional"
        }
      ];

    const jobSources = [
        {
          value: "Newspaper",
          label: "Newspaper"
        },
        {
            value: "Referred by Employee",
            label: "Referred by Employee"
        },
        {
          value: "Email",
          label: "Email"
        },
        {
          value: "Social Media (Facebook, Instagrams, Linkedin, etc)",
          label: "Social Media (Facebook, Instagrams, Linkedin, etc)"
        },
        {
          value: "School Events",
          label: "School Events"
        },
        {
            value: "Advertisement (Flyers, Handouts, Brochures, etc)",
            label: "Advertisement (Flyers, Handouts, Brochures, etc)"
        },
        {
            value: "Recommended by Friend",
            label: "Recommended by Friend"
        },
        {
            value: "Others",
            label: "Others"
        }
    ];

    const workingHours = [
        {
            value: "10 Hours",
            label: "10 Hours"
        },
        {
            value: "20 Hours",
            label: "20 Hours"
        },
        {
            value: "30 Hours",
            label: "30 Hours"
        },
        {
            value: "40 Hours",
            label: "40 Hours"
        }
    ]

    const genderOptions = [
        {
            value: "Male",
            label: "Male"
        },
        {
            value: "Female",
            label: "Female"
        },
        {
            value: "Decline To Self Identify",
            label: "Decline To Self Identify"
        }
    ]

    const hispLatOptions = [
        {
            value: "Yes",
            label: "Yes"
        },
        {
            value: "No",
            label: "No"
        },
        {
            value: "Decline To Self Identify",
            label: "Decline To Self Identify"
        }
    ]

    const veteranOptions = [
        {
            value: "I am not a protected veteran",
            label: "I am not a protected veteran"
        },
        {
            value: "I identify as one or more of the classifications of a protected veterano",
            label: "I identify as one or more of the classifications of a protected veteran"
        },
        {
            value: "I don’t wish to answer",
            label: "I don’t wish to answer"
        }
    ]

    const disabilityOptions = [
        {
            value: "Yes",
            label: "Yes"
        },
        {
            value: "No",
            label: "No"
        },
        {
            value: "I don’t wish to answer",
            label: "I don’t wish to answer"
        }
    ]

    const WorkAuthorizationOptions = [
        {
            value: "Yes",
            label: "Yes"
        },
        {
            value: "No",
            label: "No"
        }
    ]

    const workExperienceYears = [
        {
            value: "0-1 Years",
            label: "0-1 Years"
        },
        {
            value: "1-3 Years",
            label: "1-3 Years"
        },
        {
            value: "3-5 Years",
            label: "3-5 Years"
        },
        {
            value: "5-10 Years",
            label: "5-10 Years"
        },
        {
            value: "10+ Years",
            label: "10+ Years"
        }
    ]

    const RenderProgressBar = (param) => {
        switch(param){
            case "MyInformation":
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                </div>
            case "Experience":
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                </div>
            case "VoluntaryDisclosures":
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                </div>
            case "ProfessionalReferences":
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                </div>
            case "Review":
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionCompletedImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                </div>

            default:
                return <div className="mt-1 mx-8 md:mx-16 items-center flex flex-row">
                    <img src={SectionInProgressImg} className="w-6 md:w-16"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                    <hr className="w-1/6 px-2 job-progress-bar">
        
                    </hr>
                    <img src={SectionNotStartedImg} className="w-4 md:w-12"></img>
                </div>
        }
    }

    const RenderFormBar = (param) => {
        switch(param){
            case "MyInformation":
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 px-2 md:text-lg font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Review
                    </p>
                </div>
            case "Experience":
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 px-2 md:text-lg font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-9">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-10">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-9">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-9">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Review
                    </p>
                </div>
            case "VoluntaryDisclosures":
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 px-2 md:text-lg font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-10">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-11">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-11">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-11">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Review
                    </p>
                </div>
            case "ProfessionalReferences":
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 px-2 md:text-lg font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Review
                    </p>
                </div>
            case "Review":
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 px-2 md:text-lg font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-12">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue progress-bar-text">
                        Review
                    </p>
                </div>
            default:
                return <div className="mt-1 mx-4 items-center flex flex-row shadow-lg">
                    <p className="w-1/6 md:text-lg px-2 font-volkhov dark-blue text-center progress-bar-text">
                        My Information
                    </p>
                    <div className="w-4 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Experience
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Voluntary Disclosures
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Professional References
                    </p>
                    <div className="w-3 md:w-8">
        
                    </div>
                    <p className="w-1/6 md:text-lg font-volkhov text-center dark-blue-inactive progress-bar-text">
                        Review
                    </p>
                </div>
        }
    }
   
    const MyInformationForm = (props) => {
        const [firstName, setFirstName] = useState("");
        const [fileSelected, setFileSelected] = useState(props.FormInitialValues.FileName);
        const [unsupportedFileType, setUnsupportedFileType] = useState(false);
        const [ResumeLink, setResumeLink] = useState("");
        const [uploadFile, setUploadFile] = React.useState(null);
        const FileRef = useRef();
        const [ValidForm, setValidForm] = useState(props.FormInitialValues.FormValid);

        const openChooseFilePrompt = () => {
            const node = FileRef.current;
            node.click();
        }

        const updateMyFirstName = (event) => {
            console.log(event);
            setFirstName(event.target.value);
        }   
        
        const MyInformationFormInitialValues = {
            FirstName: "",
            LastName: "",
            Email: "",
            Phone: "",
            Address: "",
            City: "",
            State: "",
            Zip: "",
            ResumeLink: "",
            UniqueFitPitch: "",
            CandidateEducationLevel: null,
            CandidateInSchool: false,
            JobSource: null,
            JobSourceContactName: ""
        };

        const FormValidityInitialValues = {
            FirstName: false,
            LastName: false,
            Email: false,
            Phone: false,
            Address: false,
            City: false,
            State: false,
            Zip: false,
            ResumeLink: false,
            UniqueFitPitch: false,
            CandidateEducationLevel: false,
            JobSource: false
        }

        const FormTouchedInitialValues = {
            FirstName: false,
            LastName: false,
            Email: false,
            Phone: false,
            Address: false,
            City: false,
            State: false,
            Zip: false,
            UniqueFitPitch: false,
            CandidateEducationLevel: false,
            JobSource: false
        }

        const FormTouchedSetValues = {
            FirstName: true,
            LastName: true,
            Email: true,
            Phone: true,
            Address: true,
            City: true,
            State: true,
            Zip: true,
            UniqueFitPitch: true,
            CandidateEducationLevel: true,
            JobSource: true
        };

        const FormErrorMessages = {
            FirstName: "Please enter your First Name",
            LastName: "Please enter your Last Name",
            Email: "Please enter your Email",
            Phone: "Please enter your Phone",
            Address: "Please enter your Address",
            City: "",
            State: "",
            Zip: "",
            UniqueFitPitch: "Please complete this section",
            CandidateEducationLevel: "Please select your Education Level",
            JobSource: "Please select an option"
        }
    
        const [myInformationFormData, setMyInformationData] = useState(props.FormInitialValues);
        const [formValidation, setFormValidation] = useState(getSessionStorageOrDefault('myInfoValidation', FormValidityInitialValues));
        const [formTouchedState, setFormTouched] = useState(getSessionStorageOrDefault('myInfoFormState', FormValidityInitialValues));
        const [formErrorMessage, setErrorMessage] = useState(FormErrorMessages);

        useEffect(() => {
            sessionStorage.setItem('myInfo', JSON.stringify(myInformationFormData));
        }, [myInformationFormData])

        useEffect(() => {
            sessionStorage.setItem('myInfoValidation', JSON.stringify(formValidation));
        }, [formValidation])

        useEffect(() => {
            sessionStorage.setItem('myInfoFormState', JSON.stringify(formTouchedState));
        }, [formTouchedState])

        useEffect(() => {
            if(ValidForm){
                setFormValidation({
                    FirstName: true,
                    LastName: true,
                    Email: true,
                    Phone: true,
                    Address: true,
                    City: true,
                    State: true,
                    Zip: true,
                    ResumeLink: true,
                    UniqueFitPitch: true,
                    CandidateEducationLevel: true,
                    JobSource: true
                });
            } else {
                setFormValidation({
                    FirstName: false,
                    LastName: false,
                    Email: false,
                    Phone: false,
                    Address: false,
                    City: false,
                    State: false,
                    Zip: false,
                    ResumeLink: false,
                    UniqueFitPitch: false,
                    CandidateEducationLevel: false,
                    JobSource: false
                });
            }
        }, [ValidForm])
        
        const handleEducationLevelChange = (event) => {
            setMyInformationData({
                ...myInformationFormData,
                CandidateEducationLevel: event.target.value
            });
        };
    
        const handleJobSourceChange = (event) => {
            setMyInformationData({
                ...myInformationFormData,
                JobSource: event.target.value
            });
        };

        const handleInSchoolChange = (event) => {
            setMyInformationData({
                ...myInformationFormData,
                CandidateInSchool: event.target.checked
            });
        };

        const handleFileInputUpdate = (event) => {
            console.log(event.target.files);
            setUploadFile(event.target.files);
            setFileSelected(event.target.files[0].name);
            setMyInformationData({...myInformationFormData, FileName: event.target.files[0].name})
            if(event.target.files[0].type === "application/pdf"){
                setUnsupportedFileType(false);
            } else {
                setUnsupportedFileType(true);
            }
        };
    
        useEffect(() => {
            if(uploadFile !== null){
    
                if(uploadFile[0].type === "application/pdf"){
                    uploadResume();
                }
            }   
        }, [uploadFile])
    
        const uploadResume = async () => {
            console.log("Uploading Resume");
            var reader = new FileReader();
                reader.readAsDataURL(uploadFile[0]);
                reader.onload = async () => {
                    console.log(reader.result);
                    let fileName = myInformationFormData.FirstName + uuidv4() + ".pdf";
                    const fileUploadDTO = {
                        "FileName": fileName,
                        //"FileName": uploadFile[0].name,
                        "FileType": "PDF",
                        "Base64FileContent": reader.result
                    };
                    console.log("File Upload DTO");
                    console.log(fileUploadDTO);
    
                    const functionAppResponse = await uploadResumeToBlob(fileUploadDTO);
    
                    console.log(functionAppResponse);

                    setResumeLink(functionAppResponse.data)
                    setMyInformationData({...myInformationFormData, ResumeLink: functionAppResponse.data})
                    
                    setFormValidation({...formValidation, ResumeLink: true})
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
        }

        const validateFirstName = (e) => {
            setFormTouched({...formTouchedState, FirstName: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, FirstName: true})
            } else {
                setFormValidation({...formValidation, FirstName: false})
            }
        }

        const validateLastName = (e) => {
            setFormTouched({...formTouchedState, LastName: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, LastName: true})
            } else {
                setFormValidation({...formValidation, LastName: false})
            }
        }

        const validateEmail = (e) => {
            setFormTouched({...formTouchedState, Email: true});
            if(e.target.value.length !== 0){
                if(isEmail(e.target.value)){
                    setFormValidation({...formValidation, Email: true})
                }
                else {
                    setFormValidation({...formValidation, Email: false})
                }
                
            } else {
                setFormValidation({...formValidation, Email: false})
            }
        }

        const validatePhone = (e) => {
            setFormTouched({...formTouchedState, Phone: true});
            if(e.target.value.length !== 0){
                if(isNumeric(e.target.value)){
                    setFormValidation({...formValidation, Phone: true})
                } else {
                    setFormValidation({...formValidation, Phone: false})
                }
                
            } else {
                setFormValidation({...formValidation, Phone: false})
            }
        }

        const validateAddress = (e) => {
            setFormTouched({...formTouchedState, Address: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Address: true})
            } else {
                setFormValidation({...formValidation, Address: false})
            }
        }

        const validateCity = (e) => {
            setFormTouched({...formTouchedState, City: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, City: true})
            } else {
                setFormValidation({...formValidation, City: false})
            }
        }

        const validateState = (e) => {
            setFormTouched({...formTouchedState, State: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, State: true})
            } else {
                setFormValidation({...formValidation, State: false})
            }
        }

        const validateZip = (e) => {
            setFormTouched({...formTouchedState, Zip: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Zip: true})
            } else {
                setFormValidation({...formValidation, Zip: false})
            }
        }

        const validateUniqueFit = (e) => {
            setFormTouched({...formTouchedState, UniqueFitPitch: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, UniqueFitPitch: true})
            } else {
                setFormValidation({...formValidation, UniqueFitPitch: false})
            }
        }

        const validateEducationLevel = (e) => {
            setFormTouched({...formTouchedState, CandidateEducationLevel: true});
            if(e.target.value !== null){
                setFormValidation({...formValidation, CandidateEducationLevel: true})
            } else {
                setFormValidation({...formValidation, CandidateEducationLevel: false})
            }
        }

        const validateJobSource = (e) => {
            setFormTouched({...formTouchedState, JobSource: true});
            if(e.target.value !== null){
                setFormValidation({...formValidation, JobSource: true})
            } else {
                setFormValidation({...formValidation, JobSource: false})
            }
        }

        const validateAutoFill = () => {
            var localValidation = {
                FirstName: false,
                LastName: false,
                Email: false,
                Phone: false,
                Address: false,
                City: false,
                State: false,
                Zip: false,
                ResumeLink: false,
                UniqueFitPitch: false,
                CandidateEducationLevel: false,
                JobSource: false
            };

            if(myInformationFormData.FirstName.length !== 0){
                setFormValidation({...formValidation, FirstName: true})
                localValidation.FirstName = true;
            }

            if(myInformationFormData.LastName.length !== 0){
                setFormValidation({...formValidation, LastName: true})
                localValidation.LastName = true;
            }

            if(myInformationFormData.Email.length !== 0){
                setFormValidation({...formValidation, Email: true})
                localValidation.Email = true;
            }

            if(myInformationFormData.Phone.length !== 0){
                setFormValidation({...formValidation, Phone: true})
                localValidation.Phone = true;
            }

            if(myInformationFormData.Address.length !== 0){
                setFormValidation({...formValidation, Address: true})
                localValidation.Address = true;
            }

            if(myInformationFormData.City.length !== 0){
                setFormValidation({...formValidation, City: true})
                localValidation.City = true;
            }

            if(myInformationFormData.State.length !== 0){
                setFormValidation({...formValidation, State: true})
                localValidation.State = true;
            }

            if(myInformationFormData.Zip.length !== 0){
                setFormValidation({...formValidation, Zip: true})
                localValidation.Zip = true;
            }

            if(myInformationFormData.ResumeLink.length !== 0){
                setFormValidation({...formValidation, ResumeLink: true})
                localValidation.ResumeLink = true;
            }

            if(myInformationFormData.UniqueFitPitch.length !== 0){
                setFormValidation({...formValidation, UniqueFitPitch: true})
                localValidation.UniqueFitPitch = true;
            }

            if(myInformationFormData.CandidateEducationLevel !== null){
                setFormValidation({...formValidation, CandidateEducationLevel: true})
                localValidation.CandidateEducationLevel = true;
            }

            if(myInformationFormData.JobSource !== null){
                setFormValidation({...formValidation, JobSource: true})
                localValidation.JobSource = true;
            }

            var hasFalseValues = Object.values(localValidation).includes(false);

            return !hasFalseValues;
        }

        const validateForm = () => {
            console.log(formValidation);

            var hasFalseValues = Object.values(formValidation).includes(false);

            var autoFillValidation = validateAutoFill();

            if(hasFalseValues && !autoFillValidation){
                setFormTouched(FormTouchedSetValues);
                setValidForm(false);
            } else {
                setValidForm(true);

                goToExperience(myInformationFormData);

                //goToWorkAvailability(myInformationFormData);
            }
        }

        return <>
        <div className="mt-1 mx-4 overflow-y-scroll job-application-height" ref={JARef}>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" 
                value={myInformationFormData.FirstName} id="first-name" label="First Name" 
                required={true} 
                onChange={e => setMyInformationData({...myInformationFormData, FirstName: e.target.value})} 
                onBlur={validateFirstName} 
                error={!formValidation.FirstName && formTouchedState.FirstName} 
                helperText={!formValidation.FirstName && formTouchedState.FirstName && formErrorMessage.FirstName} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" id="last-name" label="Last Name" 
                value={myInformationFormData.LastName} 
                onChange={e => setMyInformationData({...myInformationFormData, LastName: e.target.value})} 
                onBlur={validateLastName} 
                error={!formValidation.LastName && formTouchedState.LastName} 
                helperText={!formValidation.LastName && formTouchedState.LastName && formErrorMessage.LastName} 
                required={true} variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" id="email" label="Email" 
                value={myInformationFormData.Email} 
                onChange={e => setMyInformationData({...myInformationFormData, Email: e.target.value})} 
                onBlur={validateEmail} 
                error={!formValidation.Email && formTouchedState.Email} 
                helperText={!formValidation.Email && formTouchedState.Email && formErrorMessage.Email} 
                required={true} variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" id="phone" label="Phone" 
                value={myInformationFormData.Phone} 
                onChange={e => setMyInformationData({...myInformationFormData, Phone: e.target.value})} 
                onBlur={validatePhone} 
                error={!formValidation.Phone && formTouchedState.Phone} 
                helperText={!formValidation.Phone && formTouchedState.Phone && formErrorMessage.Phone} 
                required={true} variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" id="address" label="Address" 
                value={myInformationFormData.Address} 
                onChange={e => setMyInformationData({...myInformationFormData, Address: e.target.value})} 
                onBlur={validateAddress} 
                error={!formValidation.Address && formTouchedState.Address} 
                helperText={!formValidation.Address && formTouchedState.Address && formErrorMessage.Address} 
                required={true} variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full md:w-2/3" id="city" label="City" 
                value={myInformationFormData.City} 
                onChange={e => setMyInformationData({...myInformationFormData, City: e.target.value})} 
                onBlur={validateCity} 
                error={!formValidation.City && formTouchedState.City} 
                required={true} variant="standard" />
            </div>
            <div className="w-full md:w-2/3 mx-auto">
                <div className="mx-2 my-1 flex flex-row">
                    <div className="w-1/3 mr-6">
                        <TextField className="w-full" id="state" label="State" 
                        value={myInformationFormData.State} 
                        onChange={e => setMyInformationData({...myInformationFormData, State: e.target.value})} 
                        onBlur={validateState} 
                        error={!formValidation.State && formTouchedState.State} 
                        required={true} variant="standard" />
                    </div>
                    <div className="w-1/3 ml-6">
                        <TextField className="w-full" id="zip" label="Zip" 
                        value={myInformationFormData.Zip} 
                        onChange={e => setMyInformationData({...myInformationFormData, Zip: e.target.value})} 
                        onBlur={validateZip} 
                        error={!formValidation.Zip && formTouchedState.Zip} 
                        required={true} variant="standard" />
                    </div>
                </div>

                <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">Resume <span className="text-red-600">*</span></p>
                </div>
                <div className="mx-2 mt-2 choose-file-container">
                    <p className="font-poppins text-center text-sm primary-blue py-1 cursor-pointer" onClick={openChooseFilePrompt}>Choose File</p>
                </div>
                <input type="file" hidden={true} ref={FileRef} onChange={handleFileInputUpdate} />
                <div className="mx-2 mt-1 flex flex-row">
                    <img src={FileUploadImg} className="w-4"></img>
                    <p className="font-poppins text-left ml-2 dark-blue-inactive text-sm">
                        {fileSelected}
                    </p>
                </div>

                { unsupportedFileType ? <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                    <img src={AlertImg} className="w-6"></img>
                    <p className="font-poppins text-left ml-4 my-1 content-pink text-sm">
                        Please select a PDF file
                    </p>
                </div> : 
                <></>}

                

                <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">Tell Us What Makes You Unique</p>
                </div>

                <div className="mx-2 mt-2">
                    <TextField
                    id="outlined-multiline-static"
                    label="Max 500 characters"
                    className="w-full"
                    required={true}
                    value={myInformationFormData.UniqueFitPitch}
                    onChange={e => setMyInformationData({...myInformationFormData, UniqueFitPitch: e.target.value})}
                    onBlur={validateUniqueFit}
                    error={!formValidation.UniqueFitPitch && formTouchedState.UniqueFitPitch} 
                    helperText={!formValidation.UniqueFitPitch && formTouchedState.UniqueFitPitch && formErrorMessage.UniqueFitPitch} 
                    multiline
                    rows={5}
                    />
                </div>

                <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">Highest Education Obtained <span className="text-red-600">*</span></p>
                </div>

                <div className="mx-2 mt-1 flex flex-row">
                    <Checkbox
                        className="px-2 py-0"
                        checked={myInformationFormData.CandidateInSchool}
                        onChange={handleInSchoolChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    <p className="font-poppins text-center text-sm px-2 py-3 dark-blue">Currently Enrolled in University</p>
                </div>

                <div className="mx-2 mt-1">
                    <TextField
                    id="standard-select-education-level"
                    select
                    label="Select Education Level"
                    value={myInformationFormData.CandidateEducationLevel}
                    onChange={handleEducationLevelChange}
                    onBlur={validateEducationLevel}
                    error={!formValidation.CandidateEducationLevel && formTouchedState.CandidateEducationLevel} 
                    helperText={!formValidation.CandidateEducationLevel && formTouchedState.CandidateEducationLevel && formErrorMessage.CandidateEducationLevel} 
                    required={true}
                    className="w-full"
                    variant="standard"
                    >
                    {educationLevels.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </div>

                <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">How Did You Hear About Us?</p>
                </div>

                <div className="mx-2 mt-1">
                    <TextField
                    id="standard-select-job-source"
                    select
                    label="Select Job Source"
                    value={myInformationFormData.JobSource}
                    onChange={handleJobSourceChange}
                    onBlur={validateJobSource}
                    error={!formValidation.JobSource && formTouchedState.JobSource} 
                    helperText={!formValidation.JobSource && formTouchedState.JobSource && formErrorMessage.JobSource} 
                    required={true}
                    className="w-full"
                    variant="standard"
                    >
                    {jobSources.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                </div>

                <div className="mx-2 my-1">
                    <TextField className="w-full" id="other-job-source" value={myInformationFormData.JobSourceContactName} onChange={e => setMyInformationData({...myInformationFormData, JobSourceContactName: e.target.value})} label="Details?" variant="standard" />
                </div>

                    { ValidForm ? <></> :  <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                        <img src={AlertImg} className="w-6"></img>
                        <p className="font-poppins text-left ml-4 my-1 content-pink text-sm">
                            Please enter all required entries
                        </p>
                    </div>
                    }
            </div>
            
            </div>

            <div className="mt-2">
                <div className="px-4 py-2 submit-button-container mx-auto w-1/3 md:w-1/5">
                     <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={validateForm}>Save and Continue</p>
                </div>
             </div>
        </>
    }

    const EngineeringExperienceForm = (props) => {
        const [ValidForm, setValidForm] = useState(props.FormInitialValues.FormValid);

        const experienceFormInitialValues = {
            SoftwareYears: "",
            ProgrammingLanguages: "",
            TeamLeadership: "",
            ChallengingProblem: "",
            SystemArchitecture: "",
            WorkAuthorization: ""
        }

        const FormValidityInitialValues = {
            SoftwareYears: false,
            ProgrammingLanguages: false,
            TeamLeadership: false,
            ChallengingProblem: false,
            SystemArchitecture: false,
            WorkAuthorization: false
        }

        const FormTouchedInitialValues = {
            SoftwareYears: false,
            ProgrammingLanguages: false,
            TeamLeadership: false,
            ChallengingProblem: false,
            SystemArchitecture: false,
            WorkAuthorization: false
        }

        const FormTouchedSetValues = {
            SoftwareYears: true,
            ProgrammingLanguages: true,
            TeamLeadership: true,
            ChallengingProblem: true,
            SystemArchitecture: true,
            WorkAuthorization: true
        }

        const FormErrorMessages = {
            SoftwareYears: "Please select your years of experience",
            ProgrammingLanguages: "Please enter the programming languages you are familiar with",
            TeamLeadership: "Please describe your experience with team leadership",
            ChallengingProblem: "Please describe a challenging problem you have solved",
            SystemArchitecture: "Please describe your experience with system architecture",
            WorkAuthorization: "Please select your work authorization status"
        }

        const [experienceFormData, setExperienceFormData] = useState(props.FormInitialValues);
        const [formValidation, setFormValidation] = useState(getSessionStorageOrDefault('engineeringExperienceValidation', FormValidityInitialValues));
        const [formTouchedState, setFormTouched] = useState(getSessionStorageOrDefault('engineeringExperienceFormState', FormTouchedInitialValues));
        const [formErrorMessage, setErrorMessage] = useState(FormErrorMessages);

        useEffect(() => {
            sessionStorage.setItem('enggExperience', JSON.stringify(experienceFormData));
        }, [experienceFormData])

        useEffect(() => {
            sessionStorage.setItem('engineeringExperienceValidation', JSON.stringify(formValidation));
        }, [formValidation])

        useEffect(() => {
            sessionStorage.setItem('engineeringExperienceFormState', JSON.stringify(formTouchedState));
        }, [formTouchedState])

        const handleSoftwareYearsChange = (event) => {
            setExperienceFormData({...experienceFormData, SoftwareYears: event.target.value});
        };

        const handleProgrammingLanguagesChange = (event) => {
            setExperienceFormData({...experienceFormData, ProgrammingLanguages: event.target.value});
        };

        const handleWorkAuthorizationChange = (event) => {
            setExperienceFormData({...experienceFormData, WorkAuthorization: event.target.value});
        }

        const validateSoftwareYears = (e) => {
            setFormTouched({...formTouchedState, SoftwareYears: true});
            if(e.target.value !== null){
                setFormValidation({...formValidation, SoftwareYears: true})
            } else {
                setFormValidation({...formValidation, SoftwareYears: false})
            }
        }

        const validateProgrammingLanguages = (e) => {
            setFormTouched({...formTouchedState, ProgrammingLanguages: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, ProgrammingLanguages: true})
            } else {
                setFormValidation({...formValidation, ProgrammingLanguages: false})
            }
        }

        const validateTeamLeadership = (e) => {
            setFormTouched({...formTouchedState, TeamLeadership: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, TeamLeadership: true})
            } else {
                setFormValidation({...formValidation, TeamLeadership: false})
            }
        }

        const validateChallengingProblem = (e) => {
            setFormTouched({...formTouchedState, ChallengingProblem: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, ChallengingProblem: true})
            } else {
                setFormValidation({...formValidation, ChallengingProblem: false})
            }
        }

        const validateSystemArchitecture = (e) => {
            setFormTouched({...formTouchedState, SystemArchitecture: true});
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, SystemArchitecture: true})
            } else {
                setFormValidation({...formValidation, SystemArchitecture: false})
            }
        }

        const validateWorkAuthorization = (e) => {
            setFormTouched({...formTouchedState, WorkAuthorization: true});
            if(e.target.value !== null){
                setFormValidation({...formValidation, WorkAuthorization: true})
            } else {
                setFormValidation({...formValidation, WorkAuthorization: false})
            }
        }

        const validateAutoFill = () => {
            var localValidation = {
                SoftwareYears: false,
                ProgrammingLanguages: false,
                TeamLeadership: false,
                ChallengingProblem: false,
                SystemArchitecture: false,
                WorkAuthorization: false
            };

            if(experienceFormData.SoftwareYears.length !== 0){
                setFormValidation({...formValidation, SoftwareYears: true})
                localValidation.SoftwareYears = true;
            }

            if(experienceFormData.ProgrammingLanguages.length !== 0){
                setFormValidation({...formValidation, ProgrammingLanguages: true})
                localValidation.ProgrammingLanguages = true;
            }

            if(experienceFormData.TeamLeadership.length !== 0){
                setFormValidation({...formValidation, TeamLeadership: true})
                localValidation.TeamLeadership = true;
            }

            if(experienceFormData.ChallengingProblem.length !== 0){
                setFormValidation({...formValidation, ChallengingProblem: true})
                localValidation.ChallengingProblem = true;
            }

            if(experienceFormData.SystemArchitecture.length !== 0){
                setFormValidation({...formValidation, SystemArchitecture: true})
                localValidation.SystemArchitecture = true;
            }

            if(experienceFormData.WorkAuthorization.length !== 0){
                setFormValidation({...formValidation, WorkAuthorization: true})
                localValidation.WorkAuthorization = true;
            }

            var hasFalseValues = Object.values(localValidation).includes(false);

            return !hasFalseValues;
        }

        const validateForm = () => {
            console.log(formValidation);

            var hasFalseValues = Object.values(formValidation).includes(false);

            var autoFillValidation = validateAutoFill();

            if(hasFalseValues && !autoFillValidation){
                setFormTouched(FormTouchedSetValues);
                setValidForm(false);
            } else {
                setValidForm(true);

                goToVoluntaryDisclosures(experienceFormData);
            }
        }

        return <>
        <div className="mt-1 mx-4 overflow-y-scroll job-application-height" ref={JARef}>
            <div className="mx-2 mt-1">
                    <TextField
                    id="standard-select-experience-level"
                    select
                    label="How many years of software development experience do you have?"
                    value={experienceFormData.SoftwareYears}
                    onChange={handleSoftwareYearsChange}
                    onBlur={validateSoftwareYears}
                    error={!formValidation.SoftwareYears && formTouchedState.SoftwareYears} 
                    helperText={!formValidation.SoftwareYears && formTouchedState.SoftwareYears && formErrorMessage.SoftwareYears} 
                    required={true}
                    className="w-full"
                    variant="standard"
                    >
                    {workExperienceYears.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
            </div>

            <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">Please list the programming languages you are proficient in and provide examples of projects you have completed using them.</p>
            </div>

                <div className="mx-2 mt-2">
                    <TextField
                    id="outlined-multiline-programming-languages"
                    label="Max 500 characters"
                    className="w-full"
                    required={true}
                    value={experienceFormData.ProgrammingLanguages}
                    onChange={e => setExperienceFormData({...experienceFormData, ProgrammingLanguages: e.target.value})}
                    onBlur={validateProgrammingLanguages}
                    error={!formValidation.ProgrammingLanguages && formTouchedState.ProgrammingLanguages} 
                    helperText={!formValidation.ProgrammingLanguages && formTouchedState.ProgrammingLanguages && formErrorMessage.ProgrammingLanguages} 
                    multiline
                    rows={5}
                    />
                </div>

            <div className="mx-2 mt-2">
                    <p className="font-poppins text-left text-sm light-blue">Have you led a team before? If so, how many people were you responsible for, and what was the scope of your management duties?</p>
            </div>

                <div className="mx-2 mt-2">
                    <TextField
                    id="outlined-multiline-team-leadership"
                    label="Max 500 characters"
                    className="w-full"
                    required={true}
                    value={experienceFormData.TeamLeadership}
                    onChange={e => setExperienceFormData({...experienceFormData, TeamLeadership: e.target.value})}
                    onBlur={validateTeamLeadership}
                    error={!formValidation.TeamLeadership && formTouchedState.TeamLeadership}
                    helperText={!formValidation.TeamLeadership && formTouchedState.TeamLeadership && formErrorMessage.TeamLeadership}
                    multiline
                    rows={5}
                    />

                </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm light-blue">Discuss a challenging problem you solved in software development and how you approached it.</p>
            </div>

                <div className="mx-2 mt-2">
                    <TextField
                    id="outlined-multiline-challenging-problem"
                    label="Max 500 characters"
                    className="w-full"
                    required={true}
                    value={experienceFormData.ChallengingProblem}
                    onChange={e => setExperienceFormData({...experienceFormData, ChallengingProblem: e.target.value})}
                    onBlur={validateChallengingProblem}
                    error={!formValidation.ChallengingProblem && formTouchedState.ChallengingProblem}
                    helperText={!formValidation.ChallengingProblem && formTouchedState.ChallengingProblem && formErrorMessage.ChallengingProblem}
                    multiline
                    rows={5}
                    />
                </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm light-blue">Give an example of a system architecture you designed. What were the key considerations for scalability and maintainability?.</p>
            </div>

                <div className="mx-2 mt-2">
                    <TextField
                    id="outlined-multiline-system-architecture"
                    label="Max 500 characters"
                    className="w-full"
                    required={true}
                    value={experienceFormData.SystemArchitecture}
                    onChange={e => setExperienceFormData({...experienceFormData, SystemArchitecture: e.target.value})}
                    onBlur={validateSystemArchitecture}
                    error={!formValidation.SystemArchitecture && formTouchedState.SystemArchitecture}
                    helperText={!formValidation.SystemArchitecture && formTouchedState.SystemArchitecture && formErrorMessage.SystemArchitecture}
                    multiline
                    rows={5}
                    />
                </div>

                <div className="mx-2 mt-1">
                    <TextField
                    id="standard-select-work-authorization"
                    select
                    label="Do you have the legal right to work in the U.S. without sponsorship?"
                    value={experienceFormData.WorkAuthorization}
                    onChange={handleWorkAuthorizationChange}
                    onBlur={validateWorkAuthorization}
                    error={!formValidation.WorkAuthorization && formTouchedState.WorkAuthorization} 
                    helperText={!formValidation.WorkAuthorization && formTouchedState.WorkAuthorization && formErrorMessage.WorkAuthorization} 
                    required={true}
                    className="w-full"
                    variant="standard"
                    >
                    {WorkAuthorizationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
            </div>

            { ValidForm ? <></> :  <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                        <img src={AlertImg} className="w-6"></img>
                        <p className="font-poppins text-left ml-4 my-1 content-pink text-sm">
                            Please enter all required entries
                        </p>
                    </div>
                    }

            
        </div>

        <div className="mt-2">
                <div className="px-4 py-2 submit-button-container mx-auto w-1/3 md:w-1/5">
                     <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={validateForm}>Save and Continue</p>
                </div>
        </div>
        
        </>
    }

    const WorkAvailabilityForm = (props) => {

        const [myWAFormData, setMyWAData] = useState(props.FormInitialValues);

        const [ValidForm, setValidForm] = useState(props.FormInitialValues.FormValid);

        const FormValidityInitialValues = {
            ExpectedWorkHours: false
        }

        const FormTouchedInitialValues = {
            ExpectedWorkHours: false
        }

        const FormTouchedSetValues = {
            ExpectedWorkHours: true
        }

        const FormErrorMessages = {
            ExpectedWorkHours: "Please select your desired weekly hours"
        }

        const [formValidation, setFormValidation] = useState(getSessionStorageOrDefault('workAvailabilityValidation', FormValidityInitialValues));
        const [formTouchedState, setFormTouched] = useState(getSessionStorageOrDefault('workAvailabilityFormState', FormTouchedInitialValues));
        const [formErrorMessage, setErrorMessage] = useState(FormErrorMessages);

        const handleWorkHoursChange = (event) => {
            setMyWAData({...myWAFormData, ExpectedWorkHours: event.target.value});
        };

        useEffect(() => {
            if(ValidForm){
                setFormValidation({
                    ExpectedWorkHours: true
                });
            } else {
                setFormValidation({
                    ExpectedWorkHours: false
                });
            }
        }, [ValidForm])

        useEffect(() => {
            sessionStorage.setItem('workAvailability', JSON.stringify(myWAFormData));
        }, [myWAFormData])

        useEffect(() => {
            sessionStorage.setItem('workAvailabilityValidation', JSON.stringify(formValidation));
        }, [formValidation])

        useEffect(() => {
            sessionStorage.setItem('workAvailabilityFormState', JSON.stringify(formTouchedState));
        }, [formTouchedState])

        const validateWorkHours = (e) => {
            setFormTouched({...formTouchedState, ExpectedWorkHours: true})
            if(e.target.value !== null){
                setFormValidation({...formValidation, ExpectedWorkHours: true})
            } else {
                setFormValidation({...formValidation, ExpectedWorkHours: false})
            }
        }

        const validateForm = () => {
            console.log(formValidation);

            var hasFalseValues = Object.values(formValidation).includes(false);

            if(hasFalseValues){
                setValidForm(false);
                setFormTouched(FormTouchedSetValues);
            } else {
                setValidForm(true);

                goToVoluntaryDisclosures(myWAFormData);
            }
        }

        const goToPreviousPage = () => {
            goBackToMyInfo(myWAFormData);
        }

        return <>
        <div className="mt-1 mx-4 overflow-y-scroll job-application-height" ref={JARef}>
            <div className="mx-auto w-full md:w-2/3">
            <div className="mx-2 mt-1">
                <p className="font-poppins text-left text-sm md:text-base light-blue">How many hours are you able to work?</p>
            </div>
            
            <div className="mx-2 mt-1">
                <TextField
                id="standard-select-work-hours"
                select
                label="Please Choose One"
                value={myWAFormData.ExpectedWorkHours}
                onChange={handleWorkHoursChange}
                onBlur={validateWorkHours}
                error={!formValidation.ExpectedWorkHours && formTouchedState.ExpectedWorkHours} 
                helperText={!formValidation.ExpectedWorkHours && formTouchedState.ExpectedWorkHours && formErrorMessage.ExpectedWorkHours} 
                className="w-full"
                variant="standard"
                >
                {workingHours.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm md:text-base light-blue">Please select the time periods you are available to work <span className="text-red-600">*</span></p>
            </div>

            <div className="mx-2 mt-2">
                <div class="flex flex-row table-background">
                    <div class="w-1/6 flex flex-row">

                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-right-border">
                            <p className="font-poppins text-xs md:text-sm text-center primary-blue">Day Shift (8am-4pm)</p>
                        </div>
                        <div class="w-1/3 p-2 table-right-border">
                            <p className="font-poppins text-xs md:text-sm text-center primary-blue">Swing Shift (4pm-12am)</p>
                        </div>
                        <div class="w-1/3 p-2">
                            <p className="font-poppins text-xs md:text-sm text-center primary-blue">Night Shift (12am-8am)</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Mon</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.MonDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, MonDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.MonSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, MonSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.MonNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, MonNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Tue</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.TueDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, TueDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.TueSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, TueSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.TueNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, TueNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Wed</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.WedDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, WedDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.WedSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, WedSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.WedNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, WedNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Thu</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.ThuDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, ThuDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.ThuSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, ThuSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.ThuNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, ThuNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Fri</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.FriDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, FriDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.FriSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, FriSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.FriNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, FriNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 table-bottom-border flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Sat</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SatDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SatDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SatSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SatSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-bottom-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SatNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SatNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/6 py-5 px-2 flex flex-row table-background">
                        <p className="font-poppins text-xs md:text-sm text-center primary-blue">Sun</p>
                    </div>
                    <div class="w-5/6 flex flex-row">
                        <div class="w-1/3 p-2 table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SunDayShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SunDayShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2 table-right-border">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SunSwingShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SunSwingShift: e.target.checked})}></Checkbox>
                        </div>
                        <div class="w-1/3 p-2">
                            <Checkbox 
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={myWAFormData.SunNightShift || false} 
                            onChange={e => setMyWAData({...myWAFormData, SunNightShift: e.target.checked})}></Checkbox>
                        </div>
                    </div>
                </div>
            </div>

            { ValidForm ? <></> :  <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                    <img src={AlertImg} className="w-6"></img>
                    <p className="font-poppins text-left ml-4 my-1 content-pink text-sm md:text-base">
                        Please enter all required entries
                    </p>
                </div>
            }

            </div>
        </div>
        

            <div className="mt-2 mx-auto w-full md:w-2/3">
                <div className="flex flex-row justify-center mx-auto w-full md:w-2/3">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goToPreviousPage}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={validateForm}>Save and Continue</p>
                    </div>
                </div>
            </div>
        </>
    }

     const VoluntaryDisclosuresForm = (props) => {
        const [myVDFormData, setMyVDData] = useState(props.FormInitialValues);

        useEffect(() => {
            sessionStorage.setItem('vDisclosures', JSON.stringify(myVDFormData));
        }, [myVDFormData])

        const handleVeteranStatusChange = (event) => {
            console.log(event.target.value);
            setMyVDData({...myVDFormData, VeteranStatus: event.target.value});
        };
    
        const handleDisabilityStatusChange = (event) => {
            console.log(event.target.value);
            setMyVDData({...myVDFormData, DisabilityStatus: event.target.value});
        };
    
        const handleGenderChange = (event) => {
            console.log(event.target.value);
            setMyVDData({...myVDFormData, Gender: event.target.value});
        };
    
        const handleHispLatChange = (event) => {
            console.log(event.target.value);
            setMyVDData({...myVDFormData, HispanicLatino: event.target.value});
        };

        const [ValidForm, setValidForm] = useState(props.FormInitialValues.FormValid);

        const FormValidityInitialValues = {
            Gender: false,
            HispanicLatino: false,
            VeteranStatus: false,
            DisabilityStatus: false
        };

        const [formValidation, setFormValidation] = useState(FormValidityInitialValues);

        useEffect(() => {
            if(ValidForm){
                setFormValidation({
                    Gender: true,
                    HispanicLatino: true,
                    VeteranStatus: true,
                    DisabilityStatus: true
                });
            } else {
                setFormValidation({
                    Gender: false,
                    HispanicLatino: false,
                    VeteranStatus: false,
                    DisabilityStatus: false
                });
            }
        }, [ValidForm])

        const validateGender = (e) => {
            console.log(e.target.value);
            if(e.target.value !== null){
                setFormValidation({...formValidation, Gender: true})
            } else {
                setFormValidation({...formValidation, Gender: false})
            }
        }

        const validateHispLat = (e) => {
            if(e.target.value !== null){
                setFormValidation({...formValidation, HispanicLatino: true})
            } else {
                setFormValidation({...formValidation, HispanicLatino: false})
            }
        }

        const validateVeteran = (e) => {
            if(e.target.value !== null){
                setFormValidation({...formValidation, VeteranStatus: true})
            } else {
                setFormValidation({...formValidation, VeteranStatus: false})
            }
        }

        const validateDisability = (e) => {
            if(e.target.value !== null){
                setFormValidation({...formValidation, DisabilityStatus: true})
            } else {
                setFormValidation({...formValidation, DisabilityStatus: false})
            }
        }

        const validateForm = () => {
            console.log(formValidation);

            var hasFalseValues = Object.values(formValidation).includes(false);

            if(hasFalseValues){
                setValidForm(false);
            } else {
                setValidForm(true);

                goToProfessionalReferences(myVDFormData);
            }
        }

        const goToPreviousPage = () => {
            goBackToExperience(myVDFormData);
            //goBackToWorkAvailability(myVDFormData);
        }

        return <>
        <div className="mt-1 mx-4 overflow-y-scroll job-application-height">
        <div className="mx-auto w-full md:w-2/3" ref={JARef}>
            <div className="mx-2 mt-1">
                <p className="font-poppins font-bold text-left text-sm md:text-base light-blue">U.S. Equal Opportunity Employment Information (Completion is voluntary)</p>
            </div>

            <div className="mx-2 mt-1">
                <p className="font-poppins text-xs md:text-sm text-left text-black">Individuals seeking employment at OAH are considered without regards to race, color, religion, national origin, age, sex, marital status, ancestry, physical or mental disability, veteran status, gender identity, or sexual orientation. You are being given the opportunity to provide the following information in order to help us comply with federal and state Equal Employment Opportunity/Affirmative Action record keeping, reporting, and other legal requirements.

Completion of the form is entirely voluntary. Whatever your decision, it will not be considered in the hiring process or thereafter. Any information that you do provide will be recorded and maintained in a confidential file.</p>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm md:text-base light-blue">Gender</p>
            </div>

            <div className="mx-2 mt-1">
                <TextField
                id="standard-select-gender"
                select
                label="Please Choose One"
                value={myVDFormData.Gender}
                onChange={handleGenderChange}
                onBlur={validateGender}
                className="w-full"
                variant="standard"
                >
                {genderOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm md:text-base light-blue">Are you Hispanic/Latino?</p>
            </div>

            <div className="mx-2 mt-1">
                <TextField
                id="standard-select-gender"
                select
                label="Please Choose One"
                value={myVDFormData.HispanicLatino}
                onChange={handleHispLatChange}
                onBlur={validateHispLat}
                className="w-full"
                variant="standard"
                >
                {hispLatOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins font-bold text-left text-sm md:text-base light-blue">Race and Ethnicity definitions</p>
            </div>
            
            <div className="mx-2 mt-1">
                <p className="font-poppins text-xs md:text-sm text-left text-black">If you believe you belong to any of the categories of protected veterans listed below, please indicate by making the appropriate selection. As a government contractor subject to Vietnam Era Veterans Readjustment Assistance Act (VEVRAA), we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA. Classification of protected categories is as follows:

A "disabled veteran" is one of the following: a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was discharged or released from active duty because of a service-connected disability.

A "recently separated veteran" means any veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.

An "active duty wartime or campaign badge veteran" means a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.

An "Armed forces service medal veteran" means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985.</p>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm md:text-base light-blue">Veteran Status</p>
            </div>

            <div className="mx-2 mt-1">
                <TextField
                id="standard-select-veteran status"
                select
                label="Please Choose One"
                value={myVDFormData.VeteranStatus}
                onChange={handleVeteranStatusChange}
                onBlur={validateVeteran}
                className="w-full"
                variant="standard"
                >
                {veteranOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins font-bold text-left text-sm md:text-base light-blue">Voluntary Self-Identification of disability</p>
            </div>

            <div className="mx-2 mt-1">
                <p className="font-poppins text-xs md:text-sm text-left text-black">Why are you being asked to complete this form?

We are a federal contractor or subcontractor required by law to provide equal employment opportunity to qualified people with disabilities. We are also required to measure our progress toward having at least 7% of our workforce be individuals with disabilities. To do this, we must ask applicants and employees if they have a disability or have ever had a disability. Because a person may become disabled at any time, we ask all of our employees to update their information at least every five years.

Identifying yourself as an individual with a disability is voluntary, and we hope that you will choose to do so. Your answer will be maintained confidentially and not be seen by selecting officials or anyone else involved in making personnel decisions. Completing the form will not negatively impact you in any way, regardless of whether you have self-identified in the past. For more information about this form or the equal employment obligations of federal contractors under Section 503 of the Rehabilitation Act, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp.

How do you know if you have a disability?

You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.

Disabilities include, but are not limited to:

Autism
Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, or HIV/AIDS
Blind or low vision
Cancer
Cardiovascular or heart disease
Celiac disease
Cerebral palsy
Deaf or hard of hearing
Depression or anxiety
Diabetes
Epilepsy
Gastrointestinal disorders, for example, Crohn's Disease, or irritable bowel syndrome
Intellectual disability
Missing limbs or partially missing limbs
Nervous system condition for example, migraine headaches, Parkinson’s disease, or Multiple sclerosis (MS)
Psychiatric condition, for example, bipolar disorder, schizophrenia, PTSD, or major depression
                </p>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-left text-sm md:text-base light-blue">Disability Status</p>
            </div>

            <div className="mx-2 mt-1">
                <TextField
                id="standard-select-disability-status"
                select
                label="Please Choose One"
                value={myVDFormData.DisabilityStatus}
                onChange={handleDisabilityStatusChange}
                onBlur={validateDisability}
                className="w-full"
                variant="standard"
                >
                {disabilityOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </div>

            <div className="mx-2 mt-4">
                <p className="font-poppins text-xs md:text-sm text-left text-black">1Section 503 of the Rehabilitation Act of 1973, as amended. For more information about this form or the equal employment obligations of Federal contractors, visit the U.S. Department of Labor's Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp.

PUBLIC BURDEN STATEMENT: According to the Paperwork Reduction Act of 1995 no persons are required to respond to a collection of information unless such collection displays a valid OMB control number. This survey should take about 5 minutes to complete.</p>
            </div>

            { ValidForm ? <></> :  <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                    <img src={AlertImg} className="w-6"></img>
                    <p className="font-poppins text-left ml-4 my-1 content-pink text-sm md:text-base">
                        Please enter all required entries
                    </p>
                </div>
            }

            </div>

            </div>

            <div className="mt-2 mx-auto w-full md:w-2/3">
                <div className="flex flex-row mx-auto justify-center w-full md:w-2/3">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goToPreviousPage}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={validateForm}>Save and Continue</p>
                    </div>
                </div>
            </div>
        </>
    }

    const ProfessionalReferencesForm = (props) => {

        const [myPRFormData, setMyPRData] = useState(props.FormInitialValues);

        const [ValidForm, setValidForm] = useState(props.FormInitialValues.FormValid);

        const [myPRAckData, setMyPRAckData] = useState(props.PRAcknowledgements);

        const [AckValid, setAckValid] = useState(props.PRAcknowledgements.AllValid);

        const FormValidityInitialValues = {
            Reference1Name: false,
            Reference1Relationship: false,
            Reference1Email: false,
            Reference1Phone: false,
            Reference2Name: false,
            Reference2Relationship: false,
            Reference2Email: false,
            Reference2Phone: false,
            Reference3Name: false,
            Reference3Relationship: false,
            Reference3Email: false,
            Reference3Phone: false
        };

        const FormTouchedInitialValues = {
            Reference1Name: false,
            Reference1Relationship: false,
            Reference1Email: false,
            Reference1Phone: false,
            Reference2Name: false,
            Reference2Relationship: false,
            Reference2Email: false,
            Reference2Phone: false,
            Reference3Name: false,
            Reference3Relationship: false,
            Reference3Email: false,
            Reference3Phone: false
        }

        const FormTouchedSetValues = {
            Reference1Name: true,
            Reference1Relationship: true,
            Reference1Email: true,
            Reference1Phone: true,
            Reference2Name: true,
            Reference2Relationship: true,
            Reference2Email: true,
            Reference2Phone: true,
            Reference3Name: true,
            Reference3Relationship: true,
            Reference3Email: true,
            Reference3Phone: true
        }

        const FormErrorMessages = {
            Reference1Name: "Please enter the reference name",
            Reference1Relationship: "Please enter your relationship with reference",
            Reference1Email: "Please enter email contact for reference",
            Reference1Phone: "Please enter phone contact in correct format",
            Reference2Name: "Please enter the reference name",
            Reference2Relationship: "Please enter your relationship with reference",
            Reference2Email: "Please enter email contact for reference",
            Reference2Phone: "Please enter phone contact in correct format",
            Reference3Name: "Please enter the reference name",
            Reference3Relationship: "Please enter your relationship with reference",
            Reference3Email: "Please enter email contact for reference",
            Reference3Phone: "Please enter phone contact in correct format",
        }

        const [formValidation, setFormValidation] = useState(getSessionStorageOrDefault('pReferencesValidation', FormValidityInitialValues));
        const [formTouchedState, setFormTouched] = useState(getSessionStorageOrDefault('pReferencesFormState', FormTouchedInitialValues));
        const [formErrorMessage, setErrorMessage] = useState(FormErrorMessages);

        useEffect(() => {
            if(ValidForm){
                setFormValidation({
                    Reference1Name: true,
                    Reference1Relationship: true,
                    Reference1Email: true,
                    Reference1Phone: true,
                    Reference2Name: true,
                    Reference2Relationship: true,
                    Reference2Email: true,
                    Reference2Phone: true,
                    Reference3Name: true,
                    Reference3Relationship: true,
                    Reference3Email: true,
                    Reference3Phone: true,
                });
            } else {
                setFormValidation({
                    Reference1Name: false,
                    Reference1Relationship: false,
                    Reference1Email: false,
                    Reference1Phone: true,
                    Reference2Name: false,
                    Reference2Relationship: false,
                    Reference2Email: false,
                    Reference2Phone: true,
                    Reference3Name: false,
                    Reference3Relationship: false,
                    Reference3Email: false,
                    Reference3Phone: true,
                });
            }
        }, [ValidForm])

        useEffect(() => {
            sessionStorage.setItem('pRefAcknowledgements', JSON.stringify(myPRAckData));
            setAckValid(myPRAckData.AllValid);
        }, [myPRAckData])

        useEffect(() => {
            sessionStorage.setItem('pReferences', JSON.stringify(myPRFormData));
        }, [myPRFormData])

        useEffect(() => {
            sessionStorage.setItem('pReferencesValidation', JSON.stringify(formValidation));
        }, [formValidation])

        useEffect(() => {
            sessionStorage.setItem('pReferencesFormState', JSON.stringify(formTouchedState));
        }, [formTouchedState])

        const validateReference1Name = (e) => {
            setFormTouched({...formTouchedState, Reference1Name: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference1Name: true})
            } else {
                setFormValidation({...formValidation, Reference1Name: false})
            }
        }

        const validateReference1Relationship = (e) => {
            setFormTouched({...formTouchedState, Reference1Relationship: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference1Relationship: true})
            } else {
                setFormValidation({...formValidation, Reference1Relationship: false})
            }
        }

        const validateReference1Email = (e) => {
            setFormTouched({...formTouchedState, Reference1Email: true})
            if(e.target.value.length !== 0){
                if(isEmail(e.target.value)){
                    setFormValidation({...formValidation, Reference1Email: true})
                } else {
                    setFormValidation({...formValidation, Reference1Email: false})
                }
                
            } else {
                setFormValidation({...formValidation, Reference1Email: false})
            }
        }

        const validateReference1Phone = (e) => {
            setFormTouched({...formTouchedState, Reference1Phone: true})
            if(e.target.value.length !== 0){
                if(isNumeric(e.target.value)){
                    setFormValidation({...formValidation, Reference1Phone: true})
                } else {
                    setFormValidation({...formValidation, Reference1Phone: false})
                }
                
            } else {
                setFormValidation({...formValidation, Reference1Phone: false})
            }
        }

        const validateReference2Name = (e) => {
            setFormTouched({...formTouchedState, Reference2Name: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference2Name: true})
            } else {
                setFormValidation({...formValidation, Reference2Name: false})
            }
        }

        const validateReference2Relationship = (e) => {
            setFormTouched({...formTouchedState, Reference2Relationship: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference2Relationship: true})
            } else {
                setFormValidation({...formValidation, Reference2Relationship: false})
            }
        }

        const validateReference2Email = (e) => {
            setFormTouched({...formTouchedState, Reference2Email: true})
            if(e.target.value.length !== 0){
                if(isEmail(e.target.value)){
                    setFormValidation({...formValidation, Reference2Email: true})
                } 
                else {
                    setFormValidation({...formValidation, Reference2Email: false})
                }   
                
            } else {
                setFormValidation({...formValidation, Reference2Email: false})
            }
        }

        const validateReference2Phone = (e) => {
            setFormTouched({...formTouchedState, Reference2Phone: true})
            if(e.target.value.length !== 0){
                if(isNumeric(e.target.value)){
                    setFormValidation({...formValidation, Reference2Phone: true})
                } else {
                    setFormValidation({...formValidation, Reference2Phone: false})
                }
                
            } else {
                setFormValidation({...formValidation, Reference2Phone: false})
            }
        }

        const validateReference3Name = (e) => {
            setFormTouched({...formTouchedState, Reference3Name: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference3Name: true})
            } else {
                setFormValidation({...formValidation, Reference3Name: false})
            }
        }

        const validateReference3Relationship = (e) => {
            setFormTouched({...formTouchedState, Reference3Relationship: true})
            if(e.target.value.length !== 0){
                setFormValidation({...formValidation, Reference3Relationship: true})
            } else {
                setFormValidation({...formValidation, Reference3Relationship: false})
            }
        }

        const validateReference3Email = (e) => {
            setFormTouched({...formTouchedState, Reference3Email: true})
            if(e.target.value.length !== 0){
                if(isEmail(e.target.value)){
                    setFormValidation({...formValidation, Reference3Email: true})
                } else {
                    setFormValidation({...formValidation, Reference3Email: false})
                }
                
            } else {
                setFormValidation({...formValidation, Reference3Email: false})
            }
        }

        const validateReference3Phone = (e) => {
            setFormTouched({...formTouchedState, Reference3Phone: true})
            if(e.target.value.length !== 0){
                if(isNumeric(e.target.value)){
                    setFormValidation({...formValidation, Reference3Phone: true})
                } else {
                    setFormValidation({...formValidation, Reference3Phone: false})
                }
                
            } else {
                setFormValidation({...formValidation, Reference3Phone: false})
            }
        }

        const validateAutoFill = () => {
            var localValidation = {
                Reference1Name: false,
                Reference1Relationship: false,
                Reference1Email: false,
                Reference1Phone: false,
                Reference2Name: false,
                Reference2Relationship: false,
                Reference2Email: false,
                Reference2Phone: false,
                Reference3Name: false,
                Reference3Relationship: false,
                Reference3Email: false,
                Reference3Phone: false
            };

            if(myPRFormData.Reference1Name.length !== 0){
                setFormValidation({...formValidation, Reference1Name: true})
                localValidation.Reference1Name = true;
            }

            if(myPRFormData.Reference1Relationship.length !== 0){
                setFormValidation({...formValidation, Reference1Relationship: true})
                localValidation.Reference1Relationship = true;
            }

            if(myPRFormData.Reference1Email.length !== 0){
                if(isEmail(myPRFormData.Reference1Email)){
                    setFormValidation({...formValidation, Reference1Email: true})
                    localValidation.Reference1Email = true;
                }
            }

            if(myPRFormData.Reference1PhoneNumber.length !== 0){
                if(isNumeric(myPRFormData.Reference1PhoneNumber)){
                    setFormValidation({...formValidation, Reference1Phone: true})
                    localValidation.Reference1Phone = true;
                } else {
                    setFormValidation({...formValidation, Reference1Phone: false})
                }
            }

            if(myPRFormData.Reference2Name.length !== 0){
                setFormValidation({...formValidation, Reference2Name: true})
                localValidation.Reference2Name = true;
            }

            if(myPRFormData.Reference2Relationship.length !== 0){
                setFormValidation({...formValidation, Reference2Relationship: true})
                localValidation.Reference2Relationship = true;
            }

            if(myPRFormData.Reference2Email.length !== 0){
                if(isEmail(myPRFormData.Reference2Email)){
                    setFormValidation({...formValidation, Reference2Email: true})
                    localValidation.Reference2Email = true;
                }
            }

            if(myPRFormData.Reference2PhoneNumber.length !== 0){
                if(isNumeric(myPRFormData.Reference2PhoneNumber)){
                    setFormValidation({...formValidation, Reference2Phone: true})
                    localValidation.Reference2Phone = true;
                } else {
                    setFormValidation({...formValidation, Reference2Phone: false})
                }
            }

            if(myPRFormData.Reference3Name.length !== 0){
                setFormValidation({...formValidation, Reference3Name: true})
                localValidation.Reference3Name = true;
            }

            if(myPRFormData.Reference3Relationship.length !== 0){
                setFormValidation({...formValidation, Reference3Relationship: true})
                localValidation.Reference3Relationship = true;
            }

            if(myPRFormData.Reference3Email.length !== 0){
                if(isEmail(myPRFormData.Reference3Email)){
                    setFormValidation({...formValidation, Reference3Email: true})
                    localValidation.Reference3Email = true;
                }
            }

            if(myPRFormData.Reference3PhoneNumber.length !== 0){
                if(isNumeric(myPRFormData.Reference3PhoneNumber)){
                    setFormValidation({...formValidation, Reference3Phone: true})
                    localValidation.Reference3Phone = true;
                } else {
                    setFormValidation({...formValidation, Reference3Phone: false})
                }
            }

            var hasFalseValues = Object.values(localValidation).includes(false);

            console.log(localValidation);

            return !hasFalseValues;
        }

        const validateForm = () => {
            console.log(formValidation);

            var hasFalseValues = Object.values(formValidation).includes(false);

            console.log(hasFalseValues);

            var autoFillValidation = validateAutoFill();  
            
            console.log(autoFillValidation);

            if(hasFalseValues && !autoFillValidation){
                setValidForm(false);
                setFormTouched(FormTouchedSetValues);
            } else {
                setValidForm(true);

                goToReview(myPRFormData, myPRAckData);
            }
        }

        const goBackToPreviousPage = () => {
            goBackToVoluntaryDisclosures(myPRFormData, myPRAckData);
        }

        return <>
        { myPRAckData.AllValid ? <>
            <div className="mt-1 mx-4 overflow-y-scroll job-application-height">
            <div className="mx-auto w-full md:w-2/3" ref={JARef}>
            <div className="mx-2 mt-1">
                <p className="font-volhov text-left text-xl md:text-2xl dark-blue">Reference 1</p>
            </div>

            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-name" 
                label="Reference Name" required={true} 
                value={myPRFormData.Reference1Name} 
                onChange={e => setMyPRData({...myPRFormData, Reference1Name: e.target.value})} 
                onBlur={validateReference1Name} 
                error={!formValidation.Reference1Name && formTouchedState.Reference1Name} 
                helperText={!formValidation.Reference1Name && formTouchedState.Reference1Name && formErrorMessage.Reference1Name} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-relationship" 
                label="Reference Relationship" required={true} 
                value={myPRFormData.Reference1Relationship} 
                onChange={e => setMyPRData({...myPRFormData, Reference1Relationship: e.target.value})} 
                onBlur={validateReference1Relationship} 
                error={!formValidation.Reference1Relationship && formTouchedState.Reference1Relationship} 
                helperText={!formValidation.Reference1Relationship && formTouchedState.Reference1Relationship && formErrorMessage.Reference1Relationship} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-position" 
                label="Reference Position" 
                value={myPRFormData.Reference1Position} 
                onChange={e => setMyPRData({...myPRFormData, Reference1Position: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-company" 
                label="Reference Company" 
                value={myPRFormData.Reference1Company} 
                onChange={e => setMyPRData({...myPRFormData, Reference1Company: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-email" 
                label="Reference Email" required={true} 
                value={myPRFormData.Reference1Email} 
                onChange={e => setMyPRData({...myPRFormData, Reference1Email: e.target.value})} 
                onBlur={validateReference1Email} 
                error={!formValidation.Reference1Email && formTouchedState.Reference1Email} 
                helperText={!formValidation.Reference1Email && formTouchedState.Reference1Email && formErrorMessage.Reference1Email} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-1-phone" 
                label="Reference Phone Number" required={true}
                value={myPRFormData.Reference1PhoneNumber}
                onChange={e => setMyPRData({...myPRFormData, Reference1PhoneNumber: e.target.value})} 
                onBlur={validateReference1Phone}
                error={!formValidation.Reference1Phone && formTouchedState.Reference1Phone}
                helperText={!formValidation.Reference1Phone && formTouchedState.Reference1Phone && formErrorMessage.Reference1Phone} 
                variant="standard" />
            </div>

            <div className="mx-2 mt-5">
                <p className="font-volhov text-left text-xl md:text-2xl dark-blue">Reference 2</p>
            </div>

            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-name" 
                label="Reference Name" required={true} 
                value={myPRFormData.Reference2Name} 
                onChange={e => setMyPRData({...myPRFormData, Reference2Name: e.target.value})} 
                onBlur={validateReference2Name} 
                error={!formValidation.Reference2Name && formTouchedState.Reference2Name} 
                helperText={!formValidation.Reference2Name && formTouchedState.Reference2Name && formErrorMessage.Reference2Name} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-relationship" 
                label="Reference Relationship" required={true} 
                value={myPRFormData.Reference2Relationship} 
                onChange={e => setMyPRData({...myPRFormData, Reference2Relationship: e.target.value})} 
                onBlur={validateReference2Relationship}
                error={!formValidation.Reference2Relationship && formTouchedState.Reference2Relationship} 
                helperText={!formValidation.Reference2Relationship && formTouchedState.Reference2Relationship && formErrorMessage.Reference2Relationship}  
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-position" 
                label="Reference Position" 
                value={myPRFormData.Reference2Position} 
                onChange={e => setMyPRData({...myPRFormData, Reference2Position: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-company" 
                label="Reference Company" 
                value={myPRFormData.Reference2Company} 
                onChange={e => setMyPRData({...myPRFormData, Reference2Company: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-email" 
                label="Reference Email" 
                required={true} 
                value={myPRFormData.Reference2Email} 
                onChange={e => setMyPRData({...myPRFormData, Reference2Email: e.target.value})} 
                onBlur={validateReference2Email} 
                error={!formValidation.Reference2Email && formTouchedState.Reference2Email} 
                helperText={!formValidation.Reference2Email && formTouchedState.Reference2Email && formErrorMessage.Reference2Email} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-2-phone" 
                label="Reference Phone Number" required={true}
                value={myPRFormData.Reference2PhoneNumber}
                onBlur={validateReference2Phone}
                error={!formValidation.Reference2Phone && formTouchedState.Reference2Phone}
                helperText={!formValidation.Reference2Phone && formTouchedState.Reference2Phone && formErrorMessage.Reference2Phone} 
                onChange={e => setMyPRData({...myPRFormData, Reference2PhoneNumber: e.target.value})} 
                variant="standard" />
            </div>

            <div className="mx-2 mt-5">
                <p className="font-volhov text-left text-xl md:text-2xl dark-blue">Reference 3</p>
            </div>

            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-name" 
                label="Reference Name" required={true} 
                value={myPRFormData.Reference3Name} 
                onChange={e => setMyPRData({...myPRFormData, Reference3Name: e.target.value})} 
                onBlur={validateReference3Name} 
                error={!formValidation.Reference3Name && formTouchedState.Reference3Name} 
                helperText={!formValidation.Reference3Name && formTouchedState.Reference3Name && formErrorMessage.Reference3Name} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-relationship" 
                label="Reference Relationship" required={true} 
                value={myPRFormData.Reference3Relationship} 
                onChange={e => setMyPRData({...myPRFormData, Reference3Relationship: e.target.value})} 
                onBlur={validateReference3Relationship} 
                error={!formValidation.Reference3Relationship && formTouchedState.Reference3Relationship} 
                helperText={!formValidation.Reference3Relationship && formTouchedState.Reference3Relationship && formErrorMessage.Reference3Relationship} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-position" 
                label="Reference Position" 
                value={myPRFormData.Reference3Position} 
                onChange={e => setMyPRData({...myPRFormData, Reference3Position: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-company" 
                label="Reference Company" 
                value={myPRFormData.Reference3Company} 
                onChange={e => setMyPRData({...myPRFormData, Reference3Company: e.target.value})} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-email" 
                label="Reference Email" required={true} 
                value={myPRFormData.Reference3Email} 
                onChange={e => setMyPRData({...myPRFormData, Reference3Email: e.target.value})} 
                onBlur={validateReference3Email} 
                error={!formValidation.Reference3Email && formTouchedState.Reference3Email} 
                helperText={!formValidation.Reference3Email && formTouchedState.Reference3Email && formErrorMessage.Reference3Email} 
                variant="standard" />
            </div>
            <div className="mx-2 my-1">
                <TextField className="w-full" id="reference-3-phone" 
                label="Reference Phone Number" required={true}
                value={myPRFormData.Reference3PhoneNumber} 
                onBlur={validateReference3Phone}
                error={!formValidation.Reference3Phone && formTouchedState.Reference3Phone}
                helperText={!formValidation.Reference3Phone && formTouchedState.Reference3Phone && formErrorMessage.Reference3Phone} 
                onChange={e => setMyPRData({...myPRFormData, Reference3PhoneNumber: e.target.value})} 
                variant="standard" />
            </div>

            { ValidForm ? <></> :  <div className="mx-3 p-2 mt-1 flex flex-row form-notification-container">
                    <img src={AlertImg} className="w-6"></img>
                    <p className="font-poppins text-left ml-4 my-1 content-pink text-sm md:text-base">
                        Please enter all required entries
                    </p>
                </div>
            }

            </div>

        </div>

        <div className="mt-2 mx-auto w-full md:w-2/3">
                <div className="flex flex-row justify-center mx-auto w-full md:w-2/3">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goBackToPreviousPage}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={validateForm}>Save and Continue</p>
                    </div>
                </div>
        </div>
        </> : <>
        
        <div className="mt-1 mx-4 overflow-y-scroll job-application-height">
            <div className="mx-auto w-full md:w-2/3" ref={JARef}>
            <div className="mx-2 mt-1">
                <p className="font-poppins font-bold text-left text-base md:text-lg light-blue">Who can be a professional reference</p>
            </div>

            <div className="mx-2 mt-1">
                <p className="font-poppins text-sm md:text-base text-left text-black">- Use Managers or Senior Leads in your current or previous jobs</p>
            </div>

            <div className="mx-2 mt-1">
                <p className="font-poppins text-sm md:text-base text-left text-black">- If you are a recent graduate or a student, you can use one advisor or instructor</p>
            </div>

            <div className="mx-2 mt-1">
                <p className="font-poppins text-sm md:text-base text-left text-black">- Don't use family as a reference</p>
            </div>

            <div className="mx-2 mt-2">
                <p className="font-poppins text-sm md:text-base text-left text-black">Please remember to let your references know you are using them as a reference.</p>
            </div>

            <div className="flex flex-row mx-2 mt-2">
                <div className="px-2">
                    <Checkbox 
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={myPRAckData.AllValid || false} 
                        onChange={e => setMyPRAckData({...myPRAckData, AllValid: e.target.checked})}></Checkbox>
                </div>
                <div className="px-2">
                    <p className="font-poppins text-sm md:text-base py-2 text-left text-black">I understand</p>
                </div>
                
            </div>

            </div>
        </div>

        <div className="mt-2 mx-auto w-full md:w-2/3">
                <div className="flex flex-row justify-center mx-auto w-full md:w-2/3">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goBackToPreviousPage}>Back</p>
                    </div>
                </div>
        </div>

        </> }

        
        </>
    }

    const ReviewForm = () => {
        const backToMyInfo = () => {
            //goBackToMyInfo(globalInformationFormData);
            setPage("MyInformation");

            scrollToTopOfApplication();
        }

        const backToWA = () => {
            //goBackToWorkAvailability(globalWAFormData);
            setPage("WorkAvailability");

            scrollToTopOfApplication();
        }

        const backToEE = () => {
            //goBackToEqualEmployment(globalEEFormData);
            setPage("Experience");

            scrollToTopOfApplication();
        }

        const backToVD = () => {
            //goBackToVoluntaryDisclosures(globalVDFormData);
            setPage("VoluntaryDisclosures");

            scrollToTopOfApplication();
        }

        const backToPR = () => {
            //goBackToProfessionalReferences();
            setPage("ProfessionalReferences");

            scrollToTopOfApplication();
        }

        return <>
            <div className="mt-1 mx-4 overflow-y-scroll job-application-height">

            
                <div className="mx-auto w-full md:w-2/3" ref={JARef}>
                    <div className="mx-2 mt-1 flex flex-row pb-2 review-header-container">
                        <p className="font-volhov text-left text-xl md:text-2xl dark-blue flex flex-grow">My Information</p>

                        <div className="px-1 flex flex-row edit-button-container float-right cursor-pointer" onClick={backToMyInfo}>
                        <img src={EditImg} className="w-4"></img>
                        <p className="font-poppins text-left ml-1 py-1 content-text-blue text-sm">
                            Edit
                        </p>
                        </div>
                
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">First Name: </span> {globalInformationFormData.FirstName}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Last Name: </span> {globalInformationFormData.LastName}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Email: </span> {globalInformationFormData.Email}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Phone: </span> {globalInformationFormData.Phone}</p>
                    </div>
                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Address: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalInformationFormData.Address}</p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalInformationFormData.City}, {globalInformationFormData.State}, {globalInformationFormData.Zip}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Resume </span></p>
                        <div className="mt-1 flex flex-row">
                            <img src={FileUploadImg} className="w-4"></img>
                            <p className="font-poppins text-left ml-2 dark-blue text-sm md:text-base">
                                {globalInformationFormData.FileName}
                            </p>
                        </div>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Tell Us What Makes You Unique: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalInformationFormData.UniqueFitPitch}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Education: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalInformationFormData.CandidateEducationLevel}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">How Did You Hear About Us: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalInformationFormData.JobSource}</p>
                    </div>

                    <div className="mx-2 mt-1 flex flex-row pb-2 review-header-container">
                        <p className="font-volhov text-left text-xl md:text-2xl dark-blue flex flex-grow">Engineering Experience</p>

                        <div className=" px-1 flex flex-row edit-button-container float-right cursor-pointer" onClick={backToEE}>
                        <img src={EditImg} className="w-4"></img>
                        <p className="font-poppins text-left ml-1 py-1 content-text-blue text-sm">
                            Edit
                        </p>
                        </div>
                
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Years of Experience: </span> {globalEEFormData.SoftwareYears}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Please list the programming languages you are proficient in and provide examples of projects you have completed using them.</span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalEEFormData.ProgrammingLanguages}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Have you led a team before? If so, how many people were you responsible for, and what was the scope of your management duties?</span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalEEFormData.TeamLeadership}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Discuss a challenging problem you solved in software development and how you approached it.</span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalEEFormData.ChallengingProblem}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Give an example of a system architecture you designed. What were the key considerations for scalability and maintainability?</span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalEEFormData.SystemArchitecture}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Do you have the legal right to work in the U.S. without sponsorship?: </span> </p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalEEFormData.WorkAuthorization}</p>
                    </div>
                    

                    {/* <div className="mx-2 mt-1 flex flex-row pb-2 review-header-container">
                        <p className="font-volhov text-left text-xl md:text-2xl dark-blue flex flex-grow">Work Availability</p>

                        <div className=" px-1 flex flex-row edit-button-container float-right cursor-pointer" onClick={backToWA}>
                        <img src={EditImg} className="w-4"></img>
                        <p className="font-poppins text-left ml-1 py-1 content-text-blue text-sm">
                            Edit
                        </p>
                        </div>
                
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">How many hours are you able to work?: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalWAFormData.ExpectedWorkHours}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Shifts Availability </span></p>
                        
                        { globalWAFormData.MonDayShift || globalWAFormData.MonSwingShift || globalWAFormData.MonNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Monday: </p>
                            {globalWAFormData.MonDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.MonSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.MonNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.TueDayShift || globalWAFormData.TueSwingShift || globalWAFormData.TueNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Tuesday: </p>
                            {globalWAFormData.TueDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.TueSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.TueNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.WedDayShift || globalWAFormData.WedSwingShift || globalWAFormData.WedNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Wednesday: </p>
                            {globalWAFormData.WedDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.WedSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.WedNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.ThuDayShift || globalWAFormData.ThuSwingShift || globalWAFormData.ThuNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Thursday: </p>
                            {globalWAFormData.ThuDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.ThuSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.ThuNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.FriDayShift || globalWAFormData.FriSwingShift || globalWAFormData.FriNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Friday: </p>
                            {globalWAFormData.FriDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.FriSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.FriNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.SatDayShift || globalWAFormData.SatSwingShift || globalWAFormData.SatNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Saturday: </p>
                            {globalWAFormData.SatDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.SatSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.SatNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }

                        { globalWAFormData.SunDayShift || globalWAFormData.SunSwingShift || globalWAFormData.SunNightShift ? 
                        <div className="flex flex-row">
                            <p className="font-poppins text-left text-sm md:text-base dark-blue mr-2">Sunday: </p>
                            {globalWAFormData.SunDayShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Day Shift </p> : <></>}
                            {globalWAFormData.SunSwingShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Swing Shift </p> : <></>}
                            {globalWAFormData.SunNightShift ? <p className="font-poppins text-left text-sm md:text-base dark-blue">Night Shift </p> : <></>}
                        </div> :
                        <></>
                        }
                        
                        
                    </div> */}

                    <div className="mx-2 mt-1 flex flex-row pb-2 review-header-container">
                        <p className="font-volhov text-left text-xl md:text-2xl dark-blue flex flex-grow">Voluntary Disclosures</p>

                        <div className="px-1 flex flex-row edit-button-container float-right cursor-pointer" onClick={backToVD}>
                        <img src={EditImg} className="w-4"></img>
                        <p className="font-poppins text-left ml-1 py-1 content-text-blue text-sm">
                            Edit
                        </p>
                        </div>
                
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Gender: </span> {globalVDFormData.Gender}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Ethnicity: </span> {globalVDFormData.HispanicLatino}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Veteran Status: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalVDFormData.VeteranStatus}</p>
                    </div>

                    <div className="mx-2 my-1 flex flex-col">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Disability Status: </span></p>
                        <p className="font-poppins text-left text-sm md:text-base dark-blue">{globalVDFormData.DisabilityStatus}</p>
                    </div>

                    <div className="mx-2 mt-1 flex flex-row pb-2 review-header-container">
                        <p className="font-volhov text-left text-xl md:text-2xl dark-blue flex flex-grow">Professional Reference</p>

                        <div className="px-1 flex flex-row edit-button-container float-right cursor-pointer" onClick={backToPR}>
                        <img src={EditImg} className="w-4"></img>
                        <p className="font-poppins text-left ml-1 py-1 content-text-blue text-sm">
                            Edit
                        </p>
                        </div>
                
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-base md:text-lg dark-blue"><span className="font-semibold">Reference 1</span></p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Name: </span> {globalPRFormData.Reference1Name}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Relationship: </span> {globalPRFormData.Reference1Relationship}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Company: </span> {globalPRFormData.Reference1Company}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Position: </span> {globalPRFormData.Reference1Position}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Email: </span> {globalPRFormData.Reference1Email}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Phone: </span> {globalPRFormData.Reference1PhoneNumber}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-base md:text-lg dark-blue"><span className="font-semibold">Reference 2</span></p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Name: </span> {globalPRFormData.Reference2Name}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Relationship: </span> {globalPRFormData.Reference2Relationship}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Company: </span> {globalPRFormData.Reference2Company}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Position: </span> {globalPRFormData.Reference2Position}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Email: </span> {globalPRFormData.Reference2Email}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Phone: </span> {globalPRFormData.Reference2PhoneNumber}</p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-base md:text-lg dark-blue"><span className="font-semibold">Reference 3</span></p>
                    </div>

                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Name: </span> {globalPRFormData.Reference3Name}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Relationship: </span> {globalPRFormData.Reference3Relationship}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Company: </span> {globalPRFormData.Reference3Company}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Position: </span> {globalPRFormData.Reference3Position}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Email: </span> {globalPRFormData.Reference3Email}</p>
                    </div>
                    <div className="mx-2 my-1">
                        <p className="font-poppins text-left text-sm md:text-base dark-blue"><span className="font-semibold">Phone: </span> {globalPRFormData.Reference3PhoneNumber}</p>
                    </div>
                </div>

            </div>
            
            <div className="px-4 py-2 submit-button-container mx-auto w-1/3 md:w-1/5">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={submitCompletedJobApplication}>Submit Application</p>
            </div>
        </>
    }

    const RenderApplicationForm = (param) => {
        switch(param){
            case "MyInformation":
                return <MyInformationForm FormInitialValues={globalInformationFormData}></MyInformationForm>
            case "Experience":
                return <EngineeringExperienceForm FormInitialValues={globalEEFormData}></EngineeringExperienceForm>
            case "VoluntaryDisclosures":
                return <VoluntaryDisclosuresForm FormInitialValues={globalVDFormData}></VoluntaryDisclosuresForm>
            case "ProfessionalReferences":
                return <ProfessionalReferencesForm FormInitialValues={globalPRFormData} PRAcknowledgements={globalPRAckData}></ProfessionalReferencesForm>
            case "Review":
                return <ReviewForm></ReviewForm>
            default:
                return <MyInformationForm FormInitialValues={globalInformationFormData}></MyInformationForm>
        }
    }

    const RenderFooterBar = (param) => {
        switch(param){
            case "MyInformation":
                return  <div className="px-4 py-2 submit-button-container mx-16">
                     <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToWorkAvailability}>Save and Continue</p>
                </div>
            case "WorkAvailability":
                return <div className="flex flex-row mx-8">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goBackToMyInfo}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToVoluntaryDisclosures}>Save and Continue</p>
                    </div>
                </div>
            case "VoluntaryDisclosures":
                return <div className="flex flex-row mx-8">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goBackToWorkAvailability}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToProfessionalReferences}>Save and Continue</p>
                    </div>
                </div>
            case "ProfessionalReferences":
                return <div className="flex flex-row mx-8">
                    <div className="px-4 py-2 back-button-container mr-6">
                    <p className="font-poppins text-black text-lg font-semibold cursor-pointer" onClick={goBackToVoluntaryDisclosures}>Back</p>
                    </div>
                    <div className="px-4 py-2 submit-button-container">
                      <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToReview}>Save and Continue</p>
                    </div>
                </div>
            case "Review":
                return <div className="px-4 py-2 submit-button-container mx-16">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer">Submit Application</p>
                </div>
            default:
                return  <div className="px-4 py-2 submit-button-container mx-16">
                     <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToWorkAvailability}>Save and Continue</p>
                </div>
        }
    }

    return <>
        <div className="flex flex-row mx-8 my-1">
            { jobRole == "Scholars In Disability" ? 
             <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToInternshipsView}>Internships / </p>
            :
            <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p> }
            {/* <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p> */}
            <p className="font-volkhov text-left text-lg md:text-xl light-gray italic font-bold"> {jobRole}</p>
        </div>

        {/* { jobApplicationFormPage === "MyInformation" ? <div className="mx-2 my-1 p-2 background-light-gray job-header-border">
            <p className="font-poppins text-base context-text-blue">Ready to start your job application?
            This process will take 10-15 minutes</p>
        </div> : <></>} */}
        

        {/* <div className="mt-1">
            <p className="font-volkhov p-4 text-xl light-blue">Patient Care Associate</p>
        </div> */}
        <div className="mx-auto w-full md:w-2/3">
            { formActive ? <>
            {RenderProgressBar(jobApplicationFormPage)}
            
            {RenderFormBar(jobApplicationFormPage)}
    
            {RenderApplicationForm(jobApplicationFormPage)}
            </>
            : <></> }
            
            {window.innerWidth > 768 ? <> 
                { showLoading ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Final_Loading_Desktop.mp4" controls={false}
                muted={true} playing={true} loop={true} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }

                { showSuccess ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Success_Desktop.mp4" controls={false}
                muted={true} playing={true} loop={false} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }

                { showFailure ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Failure_Desktop.mp4" controls={false}
                muted={true} playing={true} loop={false} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }
            </>:<>
                { showLoading ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Loading-mobile.mp4" controls={false}
                muted={true} playing={true} loop={true} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }

                { showSuccess ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Success_mobile.mp4" controls={false}
                muted={true} playing={true} loop={false} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }

                { showFailure ? 
                <div className="mx-auto w-full md:w-2/3">
                <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Failure-mobile.mp4" controls={false}
                muted={true} playing={true} loop={false} width="100%" height="100%" playsinline={true} className="my-4 mx-auto"></ReactPlayer></div> : 
                <></> }
            </>}

            
        </div>

        

        

        {/* <div className="mt-2">
            {RenderFooterBar(jobApplicationFormPage)}
        </div> */}
    </>
}

export default EnggJobApplicationPage