import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <div className="container mx-auto py-8">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor
                ligula nec nunc tincidunt, id tincidunt nunc rutrum. Sed id nunc
                tincidunt, aliquet nunc id, efficitur nunc. Sed id nunc tincidunt,
                aliquet nunc id, efficitur nunc.
            </p>
            <p className="mb-4">
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Sed id nunc tincidunt, aliquet nunc id, efficitur nunc.
                Sed id nunc tincidunt, aliquet nunc id, efficitur nunc.
            </p>
            <p className="mb-4">
                In hac habitasse platea dictumst. Sed id nunc tincidunt, aliquet nunc
                id, efficitur nunc. Sed id nunc tincidunt, aliquet nunc id, efficitur
                nunc.
            </p>
            <p className="mb-4">
                Donec auctor, nunc id tincidunt ultrices, nunc nunc consectetur nunc,
                nec lacinia nunc nunc nec nunc. Sed id nunc tincidunt, aliquet nunc id,
                efficitur nunc.
            </p>
        </div>
    );
};

export default PrivacyPolicyPage;
