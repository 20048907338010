import React from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';

function QASJobDescPage(){
    const history = useHistory();

    const goToCareersView = () => {
        history.push("/careers");
    }

    const goToCCApplicationView = () => {
        history.push("/careers/Quality-Assurance-Specialist/apply");
    }

    return <>
         <div className="flex flex-row mx-8 my-4">
                <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p>
        </div>

        <div className="sticky job-apply-header py-3 z-40 bg-white">
            <div className="mt-4 mx-10">
                <p className="font-volkhov text-2xl md:text-3xl font-bold dark-blue">
                Quality Assurance Specialist 
                </p>
            </div>

            <div className="mt-6">
                <div className="px-4 py-2 submit-button-container mx-auto w-2/3 md:w-1/5">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToCCApplicationView}>Apply for This Job</p>
                </div>
            </div>
        </div>
        

        <div className="mt-2">
            <p className="font-volkhov p-4 text-lg light-blue font-bold text-left">Position Summary</p>
        </div>

        <div>
            <p className="font-poppins p-4 text-sm md:text-base text-left content-text-blue">
            As a Quality Assurance Specialist, you will play an essential part in optimizing the quality and efficiency of services that Ohio At Home provides to our clients. Through this position, you will be integral in improving the lives of our clients through proactively enhancing Ohio At Home's service delivery through working directly with the clients, Ohio At Home providers, and administrators to ensure standards and safety regulations are maintained and continuously advancing.
            </p>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Key Responsibilities</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Client Collaboration: Work directly with clients and their support networks to pinpoint and implement improvements in their service plans.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Documentation and Compliance: Regularly review and update documentation, policies, and procedures to ensure compliance with current standards.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Service Improvement: Identify and advocate for enhancements in service quality; propose and implement actionable strategies and training programs to optimize service delivery.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Quality Reviews: Conduct annual service reviews, analyze feedback, and develop strategies for quality enhancement based on the data collected.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Supportive Administrative Roles: Assist with various administrative tasks to support quality assurance processes.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Initiative and Resource Development: Design and introduce new initiatives and resources that support quality assurance goals.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Qualifications</p>
        </div>
        
        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Educational Background: Currently pursuing a Bachelor's or Master's degree in Social Work, Psychology, Counseling, Physical Therapy, Occupational Therapy, Pre-Medicine, or a related field is preferred.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Passion for Healthcare: A keen interest in healthcare policy, advocacy, and a commitment to making a difference in the lives of individuals with developmental disabilities.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Relevant Experience: Previous experience or knowledge in the field of developmental disabilities is preferred.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Skills: Excellent communication skills, ability to work collaboratively in diverse teams, strong problem-solving skills, and proficiency in managing multiple priorities.</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Preferred Qualifications</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Education in Pre-Health studies, Education or Special Education, Psychology, Social Work, Sign Language, Healthcare Management</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">1+ year of Personal Care or Home Health experience</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Patient Care Associate Certification</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">First Aid/CPR Certifications</li>
            </ul>
        </div> */}

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Pay and Benefits</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Matching 401(k)</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Medical, Dental, and Vision Insurance for full time staff</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">PTO</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Premium rates for weekend shifts</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Flexible scheduling, Full time and Part Time opportunities</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Hundreds of Patient Care hours to list on applications</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Letters of Recommendation</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Work Details</p>
        </div>

        <div className="mx-4 mt-2">
            <p className="font-poppins text-sm md:text-base text-left content-text-blue">
            Schedule: Full-Time opportunities available. Minimum commitment of 40 hours per week. Shifts range 24/7/365.
            </p>
        </div> */}

        <div className="mt-4 mx-4">
            <p className="font-poppins text-sm md:text-base text-left light-blue italic">
            This is an exciting opportunity to join a team that truly makes a difference in the lives of others. We will provide on-the-job training, so if you are interested in this position, please apply today. Ohio At Home Healthcare Agency is an Equal Opportunity/Affirmative Action employer, and all qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, or protected veteran status.
            </p>
        </div>

        <div className="mt-12">
        <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Careers-Footer.mp4" controls={false}
            muted={true} playing={true} loop={true} playsinline={true} width="100%" height="100%" className="mx-auto"></ReactPlayer>
        </div>
    </>
}

export default QASJobDescPage