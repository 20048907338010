
import { SendGridMail } from '../Models/SendGridMail';
import { ErrorAlert } from '../Models/ErrorAlert';
import axios, { AxiosRequestConfig } from 'axios';

export const generateEmailFromErrorAlert = (errorAlert: ErrorAlert): SendGridMail => {
    if (!errorAlert) return {} as SendGridMail;
    const mail: SendGridMail = {
       personalizations: [
         {
           to: [{
             email: 'logs@ohioathome.com',
             name: 'Ohio-At-Home Alerts'
           }],
         }
       ],
       from: {
         email: "website@ohioathome.com",
         name: "Ohio At Home Website"
       },
       subject: errorAlert.subject || 'Alert: Error in provider portal website.',
       content: [
         {
           type: 'text/html',
           value: `<ul><li>Requested url: ${errorAlert.requestUrl}</li> <li>Timestamp: ${errorAlert.timestamp.toUTCString()}</li> 
           <li>Requestor email: Ohio At Home Website</li> <li>Status code: ${errorAlert.statusCode}</li> <li>Error message: ${errorAlert.errorMessage}</li></ul>`
         }
        ]
    };
    return mail;
}

export const generateJobApplicationEmail = (jobApplication: any): SendGridMail => {
    const mail: SendGridMail = {
        personalizations: [
            {
                to: [{
                    email: 'hr@ohioathome.com',
                    name: 'Ohio-At-Home HR'
                }],
            }],
        from: {
            email: "careers@ohioathome.com",
            name: "Ohio At Home Careers"
        },
        subject: `New job application for ${jobApplication.Role}`,
        content: [
            {
                type: 'text/html',
                value: `<ul><li>Role: ${jobApplication.Role}</li> <li>Timestamp: ${new Date().toUTCString()}</li> 
                <li>Requestor email: ${jobApplication.Email}</li> <li>Phone: ${jobApplication.Phone}</li> <li>Resume: ${jobApplication.Resume}</li></ul>`
            }
        ]
    };
    return mail;
}
            

export const sendHttpErrorAlert = async (errorAlert: ErrorAlert): Promise<any> => {
    const mail = generateEmailFromErrorAlert(errorAlert);

    const baseUrl = 'https://oah-alerts-app.azurewebsites.net/api/SendAlert?code=0WAHFN4ttl6AjpuVzDgJ0npx7Ra/09rR5icsHSKtz6qD4HakklPiLw==';

    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const response = await axios.post(baseUrl, mail, config);

    return response;
}

