import React from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';

function CSSJobDescPage(){
    const history = useHistory();

    const goToCareersView = () => {
        history.push("/careers");
    }

    const goToCCApplicationView = () => {
        history.push("/careers/Client-Services-Specialist/apply");
    }

    return <>
         <div className="flex flex-row mx-8 my-4">
                <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p>
        </div>

        <div className="sticky job-apply-header py-3 z-40 bg-white">
            <div className="mt-4 mx-10">
                <p className="font-volkhov text-2xl md:text-3xl font-bold dark-blue">
                Client Services Specialist
                </p>
            </div>

            <div className="mt-6">
                <div className="px-4 py-2 submit-button-container mx-auto w-2/3 md:w-1/5">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToCCApplicationView}>Apply for This Job</p>
                </div>
            </div>
        </div>
        

        <div className="mt-2">
            <p className="font-volkhov p-4 text-lg light-blue font-bold text-left">Position Summary</p>
        </div>

        <div>
            <p className="font-poppins p-4 text-sm md:text-base text-left content-text-blue">
            The Client Services Specialist role at Ohio At Home is vital for ensuring the highest quality of care and support for our clients. This position demands a commitment to excellence, strong organizational skills, and a passion for making a positive impact in the lives of those we serve. The role requires flexibility and adaptability, especially during organizational growth phases, where the specialist may need to provide in-person support to clients as required.
            </p>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Responsibilities</p>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Client Coordination and Communication</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Manage services for up to 40 hybrid clients, ensuring tailored care and support.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Serve as a liaison between clients, families, and healthcare professionals.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Conduct client assessments to adjust services as needed.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Onboard new clients, including those requiring remote support.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Scheduling and Support</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Oversee scheduling for Remote Support Associates (RSAs) and Direct Support Providers (DSPs).</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Provide in-person support as necessary, especially during staffing changes.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Actively participate in dispatch duties for hybrid clients.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Documentation and Reporting</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Maintain accurate weekly documentation for each client.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Manage confidential communication records.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Ensure adherence to company communication protocols.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Regularly report to the Director with updates and concerns.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Responsiveness and Growth</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Respond promptly to all communications.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Engage in regular meetings for progress review and documentation checks.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Demonstrate a growth mindset and seek professional development.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Team Leadership and Collaboration</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Guide and mentor team members.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Foster a positive and collaborative work environment.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Uphold confidentiality and privacy policies.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-md md:text-md font-normal text-left">Compliance and Ethical Standards</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Adhere to organizational policies and procedures.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Participate in ongoing training and stay abreast of industry advancements.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Uphold ethical conduct and professional integrity.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Expectations</p>
        </div>

        <div>
            <p className="font-poppins p-4 text-sm md:text-base text-left content-text-blue">
            In this role, attention to detail, strong time management, effective problem-solving, and emotional intelligence are crucial. You are expected to adapt to evolving responsibilities, maintain high ethical standards, and contribute proactively to our team's success.
            </p>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Requirements</p>
        </div>
        
        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Hold a Bachelor's / Master's degree in Social Work, Public Health, Healthcare Administration, or a related field.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Have experience in a healthcare or social services setting.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Possess excellent communication and organizational skills.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Ability to work independently and as part of a team.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Have a passion for making a difference in the lives of people with disabilities.</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Preferred Qualifications</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Education in Pre-Health studies, Education or Special Education, Psychology, Social Work, Sign Language, Healthcare Management</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">1+ year of Personal Care or Home Health experience</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Patient Care Associate Certification</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">First Aid/CPR Certifications</li>
            </ul>
        </div> */}

        <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Pay and Benefits</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Matching 401(k)</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Medical, Dental, and Vision Insurance for full time staff</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">PTO</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Premium rates for weekend shifts</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Flexible scheduling, Full time and Part Time opportunities</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Hundreds of Patient Care hours to list on applications</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Letters of Recommendation</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Work Details</p>
        </div>

        <div className="mx-4 mt-2">
            <p className="font-poppins text-sm md:text-base text-left content-text-blue">
            Schedule: Full-Time opportunities available. Minimum commitment of 40 hours per week. Shifts range 24/7/365.
            </p>
        </div> */}

        <div className="mt-4 mx-4">
            <p className="font-poppins text-sm md:text-base text-left light-blue italic">
            This is an exciting opportunity to join a team that truly makes a difference in the lives of others. We will provide on-the-job training, so if you are interested in this position, please apply today. Ohio At Home Healthcare Agency is an Equal Opportunity/Affirmative Action employer, and all qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, or protected veteran status.
            </p>
        </div>

        <div className="mt-12">
        <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Careers-Footer.mp4" controls={false}
            muted={true} playing={true} loop={true} playsinline={true} width="100%" height="100%" className="mx-auto"></ReactPlayer>
        </div>
    </>
}

export default CSSJobDescPage