import React from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';

function QDIJobDescPage(){
    const history = useHistory();

    const goToCareersView = () => {
        history.push("/careers");
    }

    const goToCCApplicationView = () => {
        history.push("/careers/Quality-And-Data-Intern/apply");
    }

    return <>
         <div className="flex flex-row mx-8 my-4">
                <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p>
        </div>

        <div className="sticky job-apply-header py-3 z-40 bg-white">
            <div className="mt-4 mx-10">
                <p className="font-volkhov text-2xl md:text-3xl font-bold dark-blue">
                Quality and Data Intern
                </p>
            </div>

            <div className="mt-6">
                <div className="px-4 py-2 submit-button-container mx-auto w-2/3 md:w-1/5">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToCCApplicationView}>Apply for This Job</p>
                </div>
            </div>
        </div>
        

        <div className="mt-2">
            <p className="font-volkhov p-4 text-lg light-blue font-bold text-left">Position Summary</p>
        </div>

        <div>
            <p className="font-poppins p-4 text-sm md:text-base text-left content-text-blue">
            Ohio At Home is offering a dynamic internship opportunity for students pursuing degrees in Biomedical Engineering, Computer Science, or a related technology field. This position will focus on the creation and organization of data sets for machine learning and AI applications, supporting the development and testing of new software applications, and maintaining quality assurance across our technology platforms. As a Quality Assurance and Data Management Intern, you will play a critical role in enhancing our service delivery through technology-driven solutions and real-time supervision systems.
            </p>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Key Responsibilities</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Data Management: Help develop, organize, and maintain data sets in JSON format for machine learning and AI applications. Ensure data integrity and cleanliness for optimal system performance.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Software Testing: Participate in beta testing of new applications, identify bugs, and liaise with developers to refine applications before wide-scale implementation.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Quality Assurance: Conduct thorough QA checks on applications to ensure software functionality and user satisfaction meet high standards.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Administrative Support: Assist in administrative tasks related to Remote Support Associate (RSA) activities, including session and shift supervision.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Training Development: Utilize your technical expertise to contribute to training materials and possibly conduct training sessions based on organizational needs.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Documentation: Create and maintain critical documentation including risk profiles, client profiles, and monitoring profiles to support decision-making and system enhancements.</li>
            </ul>
        </div>

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Qualifications</p>
        </div>
        
        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Educational Background: Currently pursuing a Bachelor's or Master's degree in Biomedical Engineering, Computer Science, or a related field is preferred.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Technical Proficiency: Strong understanding of data structures, JSON, and experience with AI and machine learning algorithms preferred.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Analytical Skills: Ability to analyze complex systems and data to improve software products and interfaces.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Experience: Prior involvement in software development, quality assurance, or a related field is highly advantageous.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Communication Skills: Excellent written and verbal communication skills.</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Preferred Qualifications</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Education in Pre-Health studies, Education or Special Education, Psychology, Social Work, Sign Language, Healthcare Management</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">1+ year of Personal Care or Home Health experience</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Patient Care Associate Certification</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">First Aid/CPR Certifications</li>
            </ul>
        </div> */}

        <div className="mt-2">
            <p className="font-volkhov px-4 md:px-6 text-lg md:text-xl light-blue font-bold text-left">Commitment</p>
        </div>

        <div className="mx-16 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Hours: Flexible full-time and part-time options, with around 10-12 hours weekly dedicated to client support.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Duration: This is a semester-long paid internship that may be extended based on performance and business needs.</li>
                {/* <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">PTO</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Premium rates for weekend shifts</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Flexible scheduling, Full time and Part Time opportunities</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Hundreds of Patient Care hours to list on applications</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Letters of Recommendation</li> */}
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Work Details</p>
        </div>

        <div className="mx-4 mt-2">
            <p className="font-poppins text-sm md:text-base text-left content-text-blue">
            Schedule: Full-Time opportunities available. Minimum commitment of 40 hours per week. Shifts range 24/7/365.
            </p>
        </div> */}

        <div className="mt-4 mx-4">
            <p className="font-poppins text-sm md:text-base text-left light-blue italic">
            This internship is a unique opportunity to engage with advanced technology applications in healthcare, providing substantial professional development in a supportive environment. You will gain hands-on experience in a growing field and contribute significantly to the operational excellence of Ohio At Home. Ohio At Home Healthcare Agency is an Equal Opportunity/Affirmative Action employer, and all qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, or protected veteran status.
            </p>
        </div>

        <div className="mt-12">
        <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Careers-Footer.mp4" controls={false}
            muted={true} playing={true} loop={true} playsinline={true} width="100%" height="100%" className="mx-auto"></ReactPlayer>
        </div>
    </>
}

export default QDIJobDescPage