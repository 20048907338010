import React from 'react';

const TermsOfUsePage = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
            <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod
                mauris vel nunc tincidunt, ut ultrices nisl lacinia. Sed auctor, nunc
                eget consectetur consequat, urna nunc fringilla nunc, id lacinia nunc
                nunc auctor nunc. Sed auctor, nunc eget consectetur consequat, urna
                nunc fringilla nunc, id lacinia nunc nunc auctor nunc.
            </p>
            <p className="mb-4">
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Sed auctor, nunc eget consectetur consequat, urna nunc
                fringilla nunc, id lacinia nunc nunc auctor nunc.
            </p>
            <p className="mb-4">
                Sed auctor, nunc eget consectetur consequat, urna nunc fringilla nunc,
                id lacinia nunc nunc auctor nunc. Sed auctor, nunc eget consectetur
                consequat, urna nunc fringilla nunc, id lacinia nunc nunc auctor nunc.
            </p>
            <p className="mb-4">
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Sed auctor, nunc eget consectetur consequat, urna nunc
                fringilla nunc, id lacinia nunc nunc auctor nunc.
            </p>
        </div>
    );
};

export default TermsOfUsePage;
