import logo from './logo.svg';
import './App.css';
import React, {lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import SEMJobDescPage from './Components/SEMJobDescPage';
import EnggJobApplicationPage from './Components/EnggJobApplicationPage';
import TermsOfUsePage from './Components/TermsOfUsePage';
import PrivacyPolicyPage from './Components/PrivacyPolicyPage';
import CSSJobDescPage from './Components/CSSJobDescPage';
import QASJobDescPage from './Components/QASJobDescPage';
import QDIJobDescPage from './Components/QDIJobDescPage';
//import TopNavbar from './Components/TopNavbar';
// import HomePage from './Components/HomePage';
// import MenuPage from "./Components/MenuPage";
// import ServicesPage from "./Components/ServicesPage";
// import ErrorPage from './Components/ErrorPage';
// import Footer from "./Components/Footer";
// import CareersPage from "./Components/CareersPage";
// import ContactUsPage from './Components/ContactUsPage';
// import ExperimentPage from './Components/ExperimentPage';
// import PCAJobDescPage from './Components/PCAJobDescPage';
// import PCAJobApplicationPage from './Components/PCAJobApplicationPage';
// import RSAJobDescPage from './Components/RSAJobDescPage';
// import CaseCoordJobDescPage from './Components/CaseCoordDescPage';
// import CommunityPage from './Components/CommunityPage';
// import ScrollToTop from './scrollToTop';
const TopNavbar = lazy (() => import('./Components/TopNavbar'));
const HomePage = lazy (() => import('./Components/HomePage'));
const MenuPage = lazy (() => import('./Components/MenuPage'));
const ServicesPage = lazy (() => import('./Components/ServicesPage'));
const ErrorPage = lazy (() => import('./Components/ErrorPage'));
const Footer = lazy (() => import('./Components/Footer'));
const CareersPage = lazy (() => import('./Components/CareersPage'));
const ContactUsPage = lazy (() => import('./Components/ContactUsPage'));
const ExperimentPage = lazy (() => import('./Components/ExperimentPage'));
const PCAJobDescPage = lazy (() => import('./Components/PCAJobDescPage'));
const PCAJobApplicationPage = lazy (() => import('./Components/PCAJobApplicationPage'));
const RSAJobDescPage = lazy (() => import('./Components/RSAJobDescPage'));
const CaseCoordJobDescPage = lazy (() => import('./Components/CaseCoordDescPage'));
const CommunityPage = lazy (() => import('./Components/CommunityPage'));
const ScrollToTop = lazy (() => import('./scrollToTop'));
const DSPJobDescPage = lazy (() => import('./Components/DSPJobDescPage'));
const TSSJobDescPage = lazy (() => import('./Components/TSSJobDescPage'));
const CRCJobDescPage = lazy (() => import('./Components/CRCJobDescPage'));
const RSCJobDescPage = lazy(() => import('./Components/RSCJobDescPage'));
const DSSJobDescPage = lazy(() => import('./Components/DSSJobDescPage'));
const WCAJobDescPage = lazy(() => import('./Components/WCAJobDescPage'));

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
        <div className="App">
        

          <Switch>
            <Route exact path="/">
              <TopNavbar showMenu={false}></TopNavbar>
              <HomePage />
              <Footer></Footer>
            </Route>
            <Route path="/menu">
              <TopNavbar showMenu={true}></TopNavbar>
              <MenuPage />
            </Route>
            <Route path="/services">
              <TopNavbar showMenu={false}></TopNavbar>
              <ServicesPage />
              <Footer></Footer>
            </Route>
            <Route path="/terms-of-use">
              <TopNavbar showMenu={false}></TopNavbar>
              <TermsOfUsePage />
              <Footer></Footer>
            </Route>
            <Route path="/privacy-policy">
              <TopNavbar showMenu={false}></TopNavbar>
              <PrivacyPolicyPage />
              <Footer></Footer>
            </Route>
            <Route path="/scholars">
              <TopNavbar showMenu={false}></TopNavbar>
              <CommunityPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers">
              <TopNavbar showMenu={false}></TopNavbar>
              <CareersPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/:section">
              <TopNavbar showMenu={false}></TopNavbar>
              <CareersPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/patient-care-associate">
              <TopNavbar showMenu={false}></TopNavbar>
              <PCAJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/direct-support-provider">
              <TopNavbar showMenu={false}></TopNavbar>
              <DSPJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/remote-support-associate">
              <TopNavbar showMenu={false}></TopNavbar>
              <RSAJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/telehealth-support-specialist">
              <TopNavbar showMenu={false}></TopNavbar>
              <TSSJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/client-services-coordinator">
              <TopNavbar showMenu={false}></TopNavbar>
              <CaseCoordJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/client-services-specialist">
              <TopNavbar showMenu={false}></TopNavbar>
              <CSSJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/quality-assurance-specialist">
              <TopNavbar showMenu={false}></TopNavbar>
              <QASJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/quality-and-data-intern">
              <TopNavbar showMenu={false}></TopNavbar>
              <QDIJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/client-relations-coordinator">
              <TopNavbar showMenu={false}></TopNavbar>
              <CRCJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/remote-support-services-coordinator">
              <TopNavbar showMenu={false}></TopNavbar>
              <RSCJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/director-social-services">
              <TopNavbar showMenu={false}></TopNavbar>
              <DSSJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/wellness-coach-advocate">
              <TopNavbar showMenu={false}></TopNavbar>
              <WCAJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/jobs/software-engineering-manager">
              <TopNavbar showMenu={false}></TopNavbar>
              <SEMJobDescPage />
              <Footer></Footer>
            </Route>
            <Route exact path="/careers/:role/apply">
              <TopNavbar showMenu={false}></TopNavbar>
              <PCAJobApplicationPage />
              {/* <Footer></Footer> */}
            </Route>
            <Route exact path="/careers/engineering/:role/apply">
              <TopNavbar showMenu={false}></TopNavbar>
              <EnggJobApplicationPage />
              {/* <Footer></Footer> */}
            </Route>
            <Route path="/contact-us">
              <TopNavbar showMenu={false}></TopNavbar>
              <ContactUsPage />
              <Footer></Footer>
            </Route>
            <Route path="/experiment">
              <TopNavbar showMenu={false}></TopNavbar>
              <ExperimentPage />
              <Footer></Footer>
            </Route>
            {/* <Route path="*">
              <Redirect to="/" />
            </Route> */}
            <Route>
              <TopNavbar showMenu={false}></TopNavbar>
              <ErrorPage></ErrorPage>
            </Route>
          </Switch>

          {/* <HomePage></HomePage> */}
          {/* <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </header> */}
        </div>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
