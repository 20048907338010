import axios from "axios";
import { ErrorAlert } from '../Models/ErrorAlert';
import { sendHttpErrorAlert } from "./sendGridService";

export const submitContactUsForm = async (formResponse: any) => {
    const route = 'https://oah-website-form.azurewebsites.net/api/ContactUs';

    return await axios.post(route, formResponse)
        .then(response => response)
        .catch(async error => {
            console.error('There was an error!', error);
            const errorAlert: ErrorAlert = {
                subject: `Error submitting contact us form`,
                timestamp: new Date(),
                statusCode: error.response ? error.response.status : "NA",
                requestUrl: route,
                errorMessage: error.message
            };
            await sendHttpErrorAlert(errorAlert);
            return null;
        });
}

export const submitJobApplicationForm = async (jobApplication: any) => {
    const route = 'https://oah-website-form.azurewebsites.net/api/Jobs/' + jobApplication.Role + '/apply';

    return await axios.post(route, jobApplication)
        .then(response => response)
        .catch(async error => {
            console.error('There was an error!', error);
            const errorAlert: ErrorAlert = {
                subject: `Error submitting job application form`,
                timestamp: new Date(),
                statusCode: error.response ? error.response.status : "NA",
                requestUrl: route,
                errorMessage: error.message
            };
            await sendHttpErrorAlert(errorAlert);
            return null;
        });
}

export const submitJobApplicationFormForEngg = async (jobApplication: any) => {
    const route = 'https://oah-website-form.azurewebsites.net/api/Jobs/engineering/' + jobApplication.Role + '/apply';

    return await axios.post(route, jobApplication)
        .then(response => response)
        .catch(async error => {
            console.error('There was an error!', error);
            const errorAlert: ErrorAlert = {
                subject: `Error submitting engineering job application form`,
                timestamp: new Date(),
                statusCode: error.response ? error.response.status : "NA",
                requestUrl: route,
                errorMessage: error.message
            };
            await sendHttpErrorAlert(errorAlert);
            return null;
        });
}

export const uploadResumeToBlob = async (uploadFile: any) => {
    const route = 'https://oah-website-form.azurewebsites.net/api/Resume';

    return await axios.post(route, uploadFile)
        .then(response => response)
        .catch(async error => {
            console.error('There was an error!', error);
            const errorAlert: ErrorAlert = {
                subject: `Error uploading resume to blob storage`,
                timestamp: new Date(),
                statusCode: error.response ? error.response.status : "NA",
                requestUrl: route,
                errorMessage: error.message
            };
            await sendHttpErrorAlert(errorAlert);
            return null;
        });
}