import React from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';

function SEMJobDescPage(){
    const history = useHistory();

    const goToCareersView = () => {
        history.push("/careers");
    }

    const goToSEMApplicationView = () => {
        history.push("/careers/engineering/Software-Engineering-Manager/apply");
    }

    return <>
         <div className="flex flex-row mx-8 my-4">
                <p className="font-volkhov text-left text-lg md:text-xl light-blue cursor-pointer" onClick={goToCareersView}>Careers / </p>
        </div>

        <div className="sticky job-apply-header py-3 z-40 bg-white">
            <div className="mt-4 mx-10">
                <p className="font-volkhov text-2xl md:text-3xl font-bold dark-blue">
                   Software Engineering Manager
                </p>
            </div>

            <div className="mt-6">
                <div className="px-4 py-2 submit-button-container mx-auto w-2/3 md:w-1/5">
                    <p className="font-poppins text-white text-lg font-semibold cursor-pointer" onClick={goToSEMApplicationView}>Apply for This Job</p>
                </div>
            </div>
        </div>
        

        <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Job Description</p>
        </div>

        <div>
            <p className="font-poppins p-4 md:p-6 text-sm md:text-base text-left content-text-blue">
            Lead and manage a team of software engineers and developers to drive the development of the company's products. Work collaboratively with cross-functional teams and provide guidance and mentorship to help them grow professionally and personally. Develop and implement software development processes and best practices to ensure that projects are completed on time and to a high standard. Take the lead in designing and architecting system architecture for the company’s products, ensuring they are scalable, maintainable, and extensible. Collaborate with other teams, such as product management, design, and quality assurance to ensure that software projects are aligned with business objectives and user needs. Take ownership of recruiting and expanding the engineering team, working with HR to develop and execute hiring strategies that attract top talent. Review code developed by other engineers and provide feedback to ensure high-quality, maintainable code. Work closely with graduate schools to develop relationships and opportunities for internships and capstone projects. Foster a culture of innovation and continuous improvement, encouraging the team to explore new technologies and approaches to software development.
            </p>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Responsibilities</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-decimal">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Lead and manage a team of software engineers and developers to drive the development of the company's products
                </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Work collaboratively with cross-functional teams and provide guidance and mentorship to help them grow professionally and personally
                </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Develop and implement software development processes and best practices to ensure that projects are completed on time and to a high standard
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Take the lead in designing and architecting system architecture for the company's products, ensuring they are scalable, maintainable, and extensible
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Collaborate with other teams, such as product management, design, and quality assurance to ensure that software projects are aligned with business objectives and user needs
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Take ownership of recruiting and expanding the engineering team, working with HR to develop and execute hiring strategies that attract top talent. 
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Review code developed by other engineers and provide feedback to ensure high-quality, maintainable code
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Work closely with graduate schools to develop relationships and opportunities for internships and capstone projects
                    </li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">
                Foster a culture of innovation and continuous improvement, encouraging the team to explore new technologies and approaches to software development
                    </li>
            </ul>
        </div> */}

        <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Job Requirements</p>
        </div>
        
        <div className="mx-4 mt-2">
            <ul className="list-inside list-decimal">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Bachelor's degree in Computer Engineering, Computer Science, or a closely related field</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Five (5) years of progressive experience in software engineering and project management</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Other experience and skills must include:</li>
                <ul className="list-inside list-disc ml-8">
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Building and maintaining applications that utilize modern programming languages including Go, Python, or Typescript</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Reviewing code developed by other engineers and providing feedback to ensure high-quality, maintainable code</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Developing, testing, and shipping high-quality software at scale</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Messaging, eventing technologies, and pub/sub services</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Machine learning or deep learning models</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Agile development methodologies and software development best practices and design principles</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Discussion of complex topics with technical and non-technical audiences</li>
                    <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Designing and architecting system architecture for large-scale software products</li>
                </ul>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Telecommuting is available for this position.</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Must have current authorization to be employed in the U.S. without employer sponsorship</li>
            </ul>
        </div>

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Required Experience & Skills</p>
        </div>

        <div className="mx-4 mt-2">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Building and maintaining applications that utilize modern programming languages including Go, Python, or Typescript</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Reviewing code developed by other engineers and providing feedback to ensure high-quality, maintainable code</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Developing, testing, and shipping high-quality software at scale</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Messaging, eventing technologies, and pub/sub services</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Machine learning or deep learning models</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Agile development methodologies and software development best practices and design principles</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Discussion of complex topics with technical and non-technical audiences</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Designing and architecting system architecture for large-scale software products</li>
            </ul>
        </div> */}

        {/* <div className="mt-2">
            <p className="font-volkhov p-4 md:p-6 text-lg md:text-xl light-blue font-bold text-left">Pay and Benefits</p>
        </div> */}

        <div className="mx-4 mt-6">
            <ul className="list-inside list-disc">
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Employer: 		Ohio-At-Home Health Care Agency LLC</li>
                <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">Work Location:		875 North High Street, Suite 300, Columbus, OH 43215</li>
                {/* <li className="font-poppins text-sm md:text-base text-left leading-6 content-text-blue">PTO</li> */}

                {/* <div>* For full time-staff (40 hours or more)</div> */}
            </ul>
        </div>

        <div className="mt-4 mx-4">
            <p className="font-poppins text-sm md:text-base text-left light-blue italic">
            Ohio At Home is an Equal Opportunity/Affirmative Action employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, or protected veteran status.
            </p>
        </div>

        <div className="mt-12">
        <ReactPlayer url="https://animationassets.blob.core.windows.net/oah-public/Careers-Footer.mp4" controls={false}
            muted={true} playing={true} loop={true} playsinline={true} width="100%" height="100%" className="mx-auto"></ReactPlayer>
        </div>
    </>
}

export default SEMJobDescPage